<div class="modal-header">
    <h5 class="modal-title">Help</h5>
    <fa-icon class="btn close"
             [icon]="['fas', 'times']"
             size="lg"
             (click)="bsModalRef.hide()"></fa-icon>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h6>What do the colors mean?</h6>

                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Row color</th>
                            <th>Meaning</th>
                            <th>Environment ready to use?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="build-successful">
                            <td>Green</td>
                            <td>Environment action is successful (claim or unclaim)</td>
                            <td>Yes</td>
                        </tr>
                        <tr class="build-building">
                            <td>Yellow</td>
                            <td>Environment is currently being prepared</td>
                            <td>No</td>
                        </tr>
                        <tr class="build-failed">
                            <td>Red</td>
                            <td>Environment is unhealthy and needs some action</td>
                            <td>No</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>