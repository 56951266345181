<ng-container *ngIf="(environmentClaim$ | async) != null">
    <div class="container-fluid">
        <div class="row">
            <label class="col-sm-3 col-form-label">Azure DevOps work item id</label>
            <div class="col-sm-9">
                <div class="form-control-plaintext">
                    <a [href]="(environmentClaim$ | async).azureDevOpsWorkItem.url"
                       target="_blank">{{(environmentClaim$ | async).azureDevOpsWorkItem.id}}</a>
                </div>
            </div>
        </div>

        <div class="row">
            <label class="col-sm-3 col-form-label">Environment claimed by</label>
            <div class="col-sm-9">
                <div class="form-control-plaintext">{{(environmentClaim$ | async).extendedUser.emailAddress}} ({{(environmentClaim$ | async).extendedUser.extendedProjectTeam.extendedTrack.name}} - {{(environmentClaim$ | async).extendedUser.extendedProjectTeam.name}})</div>
            </div>
        </div>

        <div class="row">
            <label class="col-sm-3 col-form-label">Project</label>
            <div class="col-sm-9">
                <div class="form-control-plaintext">{{(environmentClaim$ | async).azureDevOpsWorkItem.project}}</div>
            </div>
        </div>

        <div class="row">
            <label class="col-sm-3 col-form-label">Assignee</label>
            <div class="col-sm-9">
                <div class="form-control-plaintext">{{(environmentClaim$ | async).azureDevOpsWorkItem.assigneeEmailAddress}}</div>
            </div>
        </div>

        <div class="row">
            <label class="col-sm-3 col-form-label">Status</label>
            <div class="col-sm-9">
                <div class="form-control-plaintext">{{(environmentClaim$ | async).azureDevOpsWorkItem.status}}</div>
            </div>
        </div>

        <div class="row">
            <label class="col-sm-3 col-form-label">Tags</label>
            <div class="col-sm-9">
                <div class="form-control-plaintext">{{(environmentClaim$ | async).azureDevOpsWorkItem.tagList.length > 0 ? (environmentClaim$ | async).azureDevOpsWorkItem.tagList.join(", ") : "None"}}</div>
            </div>
        </div>
    </div>
</ng-container>