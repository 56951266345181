import { Component, EventEmitter, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceGrfd1WebApi, ISetDisableClaimingForAllClaimableEnvironmentTypesParameters } from "src/app/services/GovernanceGrfd1Api";

@Component({
    selector: "governance-grfd1-set-disable-claiming-for-all-claimable-environment-types-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class SetDisableClaimingForAllClaimableEnvironmentTypesModalComponent extends BaseComponent {
    @Output("recordSaved") public recordSavedEventEmitter: EventEmitter<void> = new EventEmitter<void>();

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceGrfd1WebApi: GovernanceGrfd1WebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {

    }

    public initializeModal(): void {

    }

    public onFormSubmit(setDisableClaimingForAllClaimableEnvironmentTypesParameters: ISetDisableClaimingForAllClaimableEnvironmentTypesParameters): void {
        let loadingModalRef = this.commonModalService.showLoadingModal("Saving record...");

        this.apiCallHelper.call$(this.governanceGrfd1WebApi.claimableEnvironmentType.setDisableClaimingForAllClaimableEnvironmentTypes$(setDisableClaimingForAllClaimableEnvironmentTypesParameters))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.recordSavedEventEmitter.emit();

                    this.bsModalRef.hide();
                },
            })
            .add(() => loadingModalRef.hide());
    }
}
