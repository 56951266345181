import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_URL } from "../InjectionTokens";
import { BaseWebApi } from "./BaseWebApi";

@Injectable()
export class CrossVistaBaselineWebApi extends BaseWebApi {
    public constructor(
        private httpClient: HttpClient,
        @Inject(API_URL) private apiUrl: string,
    ) { 
        super();
    }

    public getBaselineList$(claimableEnvironmentTypeId: number): Observable<string[]> {
        return this.httpClient.get<string[]>(this.apiUrl + "/CrossVistaBaseline/" + claimableEnvironmentTypeId, { withCredentials: true });
    }
}
