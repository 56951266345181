import { IUserInformationState } from "./IUserInformationState";
import { UserInformationActionTypes } from "./UserInformationActionTypes";
import { UserInformationActionTypesEnum } from "./UserInformationActionTypesEnum";

const initialState: IUserInformationState = {
    user: null,
};

export function userInformationReducer(state: IUserInformationState = initialState, action: UserInformationActionTypes): IUserInformationState {
    switch (action.type) {
        case UserInformationActionTypesEnum.SetUser:
            return {
                ...state,
                user: action.payload.user,
            };
        default:
            return state;
    }
}
