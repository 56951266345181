import { FormControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { Observable } from "rxjs";

type validatorFunctionType = {
    expression: (formControl: FormControl, formlyFieldConfig: FormlyFieldConfig) => (Promise<boolean> | Observable<boolean> | boolean),
    message: string | ((error: any, formlyFieldConfig: FormlyFieldConfig) => string),
}

export const buildVersionValidator = {
    expression: (formControl: FormControl, formlyFieldConfig: FormlyFieldConfig) => /^(R\-)?[0-9]+\.[0-9]+\.[0-9]+$/.test(formControl.value),
    message: (error: any, formlyFieldConfig: FormlyFieldConfig) => `"${formlyFieldConfig.formControl.value}" is not a valid build version. The required format is "<number>.<number>.<number>" or "R-<number>.<number>.<number>".`,
};