<ng-container *ngIf="(environmentClaim$ | async) != null">
    <div class="container-fluid">
        <div class="row mb-3">
            <label class="col-4 col-sm-3 col-lg-2 col-form-label">Claim information</label>
            <div class="col-8 col-sm-9 col-lg-10">
                <div class="form-control-plaintext">
                    <span>{{(environmentClaim$ | async).extendedClaimableEnvironment.extendedClaimableEnvironmentType.name}}</span>
                    <span> - environment {{(environmentClaim$ | async).extendedClaimableEnvironment.number}}</span>
                    <span> - story {{(environmentClaim$ | async).azureDevOpsWorkItem.id}}</span>
                </div>
            </div>
        </div>

        <div class="row mb-3"
             *ngIf="(environmentClaim$ | async).claimBuildJobNumber != null">
            <label class="col-4 col-sm-3 col-lg-2 col-form-label">Claim job status</label>
            <div class="col-3 col-sm-4 col-lg-4">
                <div class="alert"
                     [ngClass]="{ 'alert-success': (environmentClaim$ | async).claimBuildJobSuccessful === true, 'alert-danger': (environmentClaim$ | async).claimBuildJobSuccessful === false, 'alert-warning': (environmentClaim$ | async).claimBuildJobSuccessful == null }">
                    <div *ngIf="(environmentClaim$ | async).claimBuildJobNumber == 0">Waiting to start job.</div>
                    <div *ngIf="(environmentClaim$ | async).claimBuildJobNumber > 0 && (environmentClaim$ | async).claimBuildJobSuccessful == null">
                        <div class="spinner-border spinner-border-sm text-primary mr-3"></div>
                        <span>Job is running.</span>
                    </div>
                    <div *ngIf="(environmentClaim$ | async).claimBuildJobSuccessful === false">Job did not complete successfully.</div>
                    <div *ngIf="(environmentClaim$ | async).claimBuildJobSuccessful === true">Job completed successfully.</div>
                </div>
            </div>
            <div class="col-2 col-sm-2 col-lg-2 text-center">
                <img *ngIf="(environmentClaim$ | async).claimBuildJobNumber > 0"
                     [src]="'https://jenkins-grfd.rabobank.nl/' + (environmentClaim$ | async).extendedClaimableEnvironment.extendedClaimableEnvironmentType.jenkinsJobUrl + (environmentClaim$ | async).claimBuildJobNumber + '/badge/icon'" />
            </div>
            <div class="col-3 col-sm-3 col-lg-4">
                <a *ngIf="(environmentClaim$ | async).claimBuildJobNumber != null  && (environmentClaim$ | async).claimBuildJobNumber > 0"
                   [href]="(environmentClaim$ | async).claimBuildJobUrl"
                   target="_blank"
                   class="btn btn-primary w-100 mb-1">View claim job on Jenkins</a>
            </div>
        </div>

        <div class="row mb-3"
             *ngIf="(environmentClaim$ | async).unclaimBuildJobNumber != null">
            <label class="col-4 col-sm-3 col-lg-2 col-form-label">Unclaim job status</label>
            <div class="col-3 col-sm-4 col-lg-4">
                <div class="alert"
                     [ngClass]="{ 'alert-success': (environmentClaim$ | async).unclaimBuildJobSuccessful === true, 'alert-danger': (environmentClaim$ | async).unclaimBuildJobSuccessful === false, 'alert-warning': (environmentClaim$ | async).unclaimBuildJobSuccessful == null }">
                    <div *ngIf="(environmentClaim$ | async).unclaimBuildJobNumber == 0">Waiting to start job.</div>
                    <div *ngIf="(environmentClaim$ | async).unclaimBuildJobNumber > 0 && (environmentClaim$ | async).unclaimBuildJobSuccessful == null">
                        <div class="spinner-border spinner-border-sm text-primary mr-3"></div>
                        <span>Job is running.</span>
                    </div>
                    <div *ngIf="(environmentClaim$ | async).unclaimBuildJobSuccessful === false">Job did not complete successfully.</div>
                    <div *ngIf="(environmentClaim$ | async).unclaimBuildJobSuccessful === true">Job completed successfully.</div>
                </div>
            </div>
            <div class="col-2 col-sm-2 col-lg-2 text-center">
                <img *ngIf="(environmentClaim$ | async).unclaimBuildJobNumber > 0"
                     [src]="'https://jenkins-grfd.rabobank.nl/' + (environmentClaim$ | async).extendedClaimableEnvironment.extendedClaimableEnvironmentType.jenkinsJobUrl + (environmentClaim$ | async).unclaimBuildJobNumber + '/badge/icon'" />
            </div>
            <div class="col-3 col-sm-3 col-lg-4">
                <a *ngIf="(environmentClaim$ | async).unclaimBuildJobNumber != null  && (environmentClaim$ | async).unclaimBuildJobNumber > 0"
                   [href]="(environmentClaim$ | async).unclaimBuildJobUrl"
                   target="_blank"
                   class="btn btn-primary w-100 mb-1">View unclaim job on Jenkins</a>
            </div>
        </div>

        <div *ngFor="let environmentClaimResync of (environmentClaim$ | async).extendedEnvironmentClaimResyncList"
             class="row mb-3">
            <label class="col-4 col-sm-3 col-lg-2 col-form-label">Resync job status ({{environmentClaimResync.resyncDate | date:'short'}})</label>
            <div class="col-3 col-sm-4 col-lg-4">
                <div class="alert"
                     [ngClass]="{ 'alert-success': environmentClaimResync.resyncBuildJobSuccessful === true, 'alert-danger': environmentClaimResync.resyncBuildJobSuccessful === false, 'alert-warning': environmentClaimResync.resyncBuildJobSuccessful == null }">
                    <div *ngIf="environmentClaimResync.resyncBuildJobNumber == 0">Waiting to start job.</div>
                    <div *ngIf="environmentClaimResync.resyncBuildJobNumber > 0 && environmentClaimResync.resyncBuildJobSuccessful == null">
                        <div class="spinner-border spinner-border-sm text-primary mr-3"></div>
                        <span>Job is running.</span>
                    </div>
                    <div *ngIf="environmentClaimResync.resyncBuildJobSuccessful === false">Job did not complete successfully.</div>
                    <div *ngIf="environmentClaimResync.resyncBuildJobSuccessful === true">Job completed successfully.</div>
                </div>
            </div>
            <div class="col-2 col-sm-2 col-lg-2 text-center">
                <img *ngIf="environmentClaimResync.resyncBuildJobNumber > 0"
                     [src]="'https://jenkins-grfd.rabobank.nl/' + (environmentClaim$ | async).extendedClaimableEnvironment.extendedClaimableEnvironmentType.jenkinsJobUrl + environmentClaimResync.resyncBuildJobNumber + '/badge/icon'" />
            </div>
            <div class="col-3 col-sm-3 col-lg-4">
                <a *ngIf="environmentClaimResync.resyncBuildJobNumber != null  && environmentClaimResync.resyncBuildJobNumber > 0"
                   [href]="environmentClaimResync.resyncBuildJobUrl"
                   target="_blank"
                   class="btn btn-primary w-100 mb-1">View resync job on Jenkins</a>
            </div>
        </div>

        <div class="row"
             *ngFor="let groupedEnvironmentClaimJenkinsUpdate of groupedEnvironmentClaimJenkinsUpdateList$ | async">
            <div class="col-12">
                <h5>{{groupedEnvironmentClaimJenkinsUpdate.date | date:'mediumDate'}}</h5>

                <div class="row"
                     *ngFor="let environmentClaimJenkinsUpdate of groupedEnvironmentClaimJenkinsUpdate.environmentClaimJenkinsUpdateList">
                    <label class="col-4 col-sm-3 col-lg-2 col-form-label text-nowrap">
                        {{environmentClaimJenkinsUpdate.date | date:'mediumTime'}}
                    </label>
                    <div class="col-8 col-sm-9 col-lg-10">
                        <div class="alert"
                             [ngClass]="{ 'alert-success': environmentClaimJenkinsUpdate.successMessage == true, 'alert-danger': environmentClaimJenkinsUpdate.successMessage == false }">{{environmentClaimJenkinsUpdate.message}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>