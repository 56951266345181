import { Component, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { BaseComponent } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { buildVersionValidator } from "src/app/services/Validators";

@Component({
    selector: "governance-grfd1-update-build-version-form",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class UpdateBuildVersionFormComponent extends BaseComponent {
    @Output("formSubmit") public formSubmitEventEmitter: EventEmitter<string> = new EventEmitter<string>();

    public formGroup: FormGroup = new FormGroup({});
    public model = {} as {
        buildVersion: string,
    };
    public formlyFormOptions: FormlyFormOptions = {};
    public formlyFieldConfigList: FormlyFieldConfig[];

    public constructor(

    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.formlyFieldConfigList = [
                        {
                            key: ["buildVersion"],
                            type: "input",
                            templateOptions: {
                                label: "Build version",
                                placeholder: "Build version",
                                type: "text",
                                maxLength: 255,
                                required: true,
                            },
                            validators: {
                                buildVersion: buildVersionValidator,
                            },
                        },
                    ];
                },
            });
    }

    public onFormSubmit(): void {
        if (this.formGroup.valid) {
            this.formSubmitEventEmitter.emit(this.model.buildVersion);
        }
    }
}