import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceGrfd1WebApi } from "src/app/services/GovernanceGrfd1Api";
import { IDataProvider } from "../../manage/azure-devops-work-item-information/IDataProvider";
import { ICurrentEnvironment } from "../../manage/current-environment-claim-overview/ICurrentEnvironment";

@Component({
    selector: "governance-grfd1-azure-devops-work-item-information-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class AzureDevOpsWorkItemInformationModalComponent extends BaseComponent {
    public currentEnvironment: ICurrentEnvironment;
    
    public dataProvider: IDataProvider = {
        getEnvironmentClaim$: (id) => {
            return this.extendedObjectApiCallHelper.getEnvironmentClaimWithAzureDevOpsWorkItem$(id);
        },

        environmentClaimListUpdated$: this.extendedObjectApiCallHelper.extendedEnvironmentClaimListUpdated$,
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceGrfd1WebApi: GovernanceGrfd1WebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        
    }

    public initializeModal(currentEnvironment: ICurrentEnvironment): void {
        this.currentEnvironment = currentEnvironment;
    }
}
