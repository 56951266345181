import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginatedRequestParameters, IPaginatedResult } from "rabobank-utilities";
import { Observable } from "rxjs";
import { API_URL } from "../InjectionTokens";
import { ITrack } from "../models/ITrack";
import { BaseWebApi } from "./BaseWebApi";

@Injectable()
export class TrackWebApi extends BaseWebApi {
    public constructor(
        private httpClient: HttpClient,
        @Inject(API_URL) private apiUrl: string,
    ) { 
        super();
    }

    public getPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<ITrack>> {
        return this.httpClient.get<IPaginatedResult<ITrack>>(this.apiUrl + "/Track?" + this.getSieveQueryString(paginatedRequestParameters), { withCredentials: true });
    }

    public create$(track: ITrack): Observable<number> {
        return this.httpClient.post<number>(this.apiUrl + "/Track", track, { withCredentials: true });
    }

    public update$(id: number, track: ITrack): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/Track/" + id, track, { withCredentials: true });
    }

    public delete$(id: number): Observable<void> {
        return this.httpClient.delete<void>(this.apiUrl + "/Track/" + id, { withCredentials: true });
    }
}
