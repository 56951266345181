import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginatedRequestParameters, IPaginatedResult } from "rabobank-utilities";
import { Observable } from "rxjs";
import { API_URL } from "../InjectionTokens";
import { IClaimableEnvironment } from "../models/IClaimableEnvironment";
import { BaseWebApi } from "./BaseWebApi";

@Injectable()
export class ClaimableEnvironmentWebApi extends BaseWebApi {
    public constructor(
        private httpClient: HttpClient,
        @Inject(API_URL) private apiUrl: string,
    ) { 
        super();
    }

    public getPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IClaimableEnvironment>> {
        return this.httpClient.get<IPaginatedResult<IClaimableEnvironment>>(this.apiUrl + "/ClaimableEnvironment?" + this.getSieveQueryString(paginatedRequestParameters), { withCredentials: true });
    }

    public create$(claimableEnvironment: IClaimableEnvironment): Observable<number> {
        return this.httpClient.post<number>(this.apiUrl + "/ClaimableEnvironment", claimableEnvironment, { withCredentials: true });
    }

    public update$(id: number, claimableEnvironment: IClaimableEnvironment): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/ClaimableEnvironment/" + id, claimableEnvironment, { withCredentials: true });
    }

    public delete$(id: number): Observable<void> {
        return this.httpClient.delete<void>(this.apiUrl + "/ClaimableEnvironment/" + id, { withCredentials: true });
    }
}
