import { INavbarState } from "./INavbarState";
import { NavbarActionTypes } from "./NavbarActionTypes";
import { NavbarActionTypesEnum } from "./NavbarActionTypesEnum";

const initialState: INavbarState = {
    title: "",
    menuItemList: [],
};

export function navbarReducer(state: INavbarState = initialState, action: NavbarActionTypes): INavbarState {
    switch (action.type) {
        case NavbarActionTypesEnum.SetNavbarTitle:
            return {
                ...state,
                title: action.payload.title,
            };
        case NavbarActionTypesEnum.SetNavbarMenuItemList:
            return {
                ...state,
                menuItemList: action.payload.menuItemList,
            };
        default:
            return state;
    }
}
