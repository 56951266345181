import { NgModule } from "@angular/core";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { GovernanceGrfd1Hub } from "./GovernanceGrfd1Hub";
import { GovernanceGrfd1WebApi } from "./GovernanceGrfd1WebApi";
import { GovernanceGrfd1DataHub } from "./hubs/GovernanceGrfd1DataHub";
import { AzureDevOpsWorkItemWebApi } from "./webapis/AzureDevOpsWorkItemWebApi";
import { ClaimableEnvironmentTypeWebApi } from "./webapis/ClaimableEnvironmentTypeWebApi";
import { ClaimableEnvironmentWebApi } from "./webapis/ClaimableEnvironmentWebApi";
import { CrossVistaBaselineWebApi } from "./webapis/CrossVistaBaselineWebApi";
import { CrossVistaTrackTemplateWebApi } from "./webapis/CrossVistaTrackTemplateWebApi";
import { EnvironmentClaimWebApi } from "./webapis/EnvironmentClaimWebApi";
import { ProjectTeamWebApi } from "./webapis/ProjectTeamWebApi";
import { TrackWebApi } from "./webapis/TrackWebApi";
import { UserWebApi } from "./webapis/UserWebApi";

@NgModule({
    providers: [
        HubConnectionBuilder,
        GovernanceGrfd1Hub,
        GovernanceGrfd1DataHub,
        GovernanceGrfd1WebApi,
        AzureDevOpsWorkItemWebApi,
        ClaimableEnvironmentTypeWebApi,
        ClaimableEnvironmentWebApi,
        CrossVistaBaselineWebApi,
        CrossVistaTrackTemplateWebApi,
        EnvironmentClaimWebApi,
        ProjectTeamWebApi,
        TrackWebApi,
        UserWebApi,
    ],
})
export class GovernanceGrfd1ApiModule { }