<rabobank-utilities-paginated-filter-table [configuration]="paginatedFilterTableConfiguration"
                                           [paginatedResult$]="paginatedResult$"></rabobank-utilities-paginated-filter-table>

<ng-template #tableHeaderCellAdd>
    <button class="btn btn-link text-success pr-0 pl-0"
            tooltip="Add new"
            (click)="onAddRecord()">
        <fa-icon class="mr-1"
                 [icon]="['fas','plus']"></fa-icon>
    </button>
</ng-template>

<ng-template #tableBodyCellEdit
             let-item>
    <button class="btn btn-link text-primary pr-0 pl-0"
            (click)="onEditRecord(item.id)">
        <fa-icon class="mr-1"
                 tooltip="Edit"
                 [icon]="['fas','edit']"></fa-icon>
    </button>
</ng-template>

<ng-template #tableBodyCellDelete
             let-item>
    <button class="btn btn-link text-danger pr-0 pl-0"
            (click)="onDeleteRecord(item.id)">
        <fa-icon class="mr-1"
                 tooltip="Delete"
                 [icon]="['fas','trash']"></fa-icon>
    </button>
</ng-template>