import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_URL } from "../InjectionTokens";
import { IAzureDevOpsWorkItem } from "../models/IAzureDevOpsWorkItem";
import { BaseWebApi } from "./BaseWebApi";

@Injectable()
export class AzureDevOpsWorkItemWebApi extends BaseWebApi {
    public constructor(
        private httpClient: HttpClient,
        @Inject(API_URL) private apiUrl: string,
    ) { 
        super();
    }

    public getList$(azureDevOpsWorkItemIdList: number[]): Observable<IAzureDevOpsWorkItem[]> {
        return this.httpClient.get<IAzureDevOpsWorkItem[]>(this.apiUrl + "/AzureDevOpsWorkItem?" + azureDevOpsWorkItemIdList.map(x => "idList=" + x).join("&"), { withCredentials: true });
    }
}
