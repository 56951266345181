<form [formGroup]="formGroup"
      (ngSubmit)="onFormSubmit()">

    <p>It looks like you haven't accessed GovernanceGrfd1 before. Users are required to select the team they're in before they can start using GovernanceGrfd1.</p>

    <formly-form [model]="model"
                 [options]="formlyFormOptions"
                 [fields]="formlyFieldConfigList"
                 [form]="formGroup"></formly-form>

    <button type="submit"
            class="btn btn-primary submit-button"
            [disabled]="formGroup.invalid">Submit</button>

    <button type="button"
            class="btn btn-default"
            (click)="formlyFormOptions.resetModel!()">Reset</button>
</form>