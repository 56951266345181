import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { IMenuItem } from "rabobank-layout";
import { IAppState } from "../IAppState";
import { ISetNavbarMenuItemListAction, ISetNavbarTitleAction } from "./NavbarActionTypes";
import { NavbarActionTypesEnum } from "./NavbarActionTypesEnum";

@Injectable()
export class NavbarActions {
    public constructor(
        private store: Store<IAppState>,
    ) { }

    public setNavbarTitle(title: string): void {
        this.store.dispatch<ISetNavbarTitleAction>({
            type: NavbarActionTypesEnum.SetNavbarTitle,
            payload: {
                title: title,
            },
        });
    }

    public setNavbarMenuItemList(menuItemList: IMenuItem[]): void {
        this.store.dispatch<ISetNavbarMenuItemListAction>({
            type: NavbarActionTypesEnum.SetNavbarMenuItemList,
            payload: {
                menuItemList: menuItemList,
            },
        });
    }
}
