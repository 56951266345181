import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginatedRequestParameters, IPaginatedResult } from "rabobank-utilities";
import { Observable } from "rxjs";
import { API_URL } from "../InjectionTokens";
import { IEnvironmentClaim } from "../models/IEnvironmentClaim";
import { IStartEnvironmentClaimParameters } from "../models/parameters/IStartEnvironmentClaimParameters";
import { BaseWebApi } from "./BaseWebApi";

@Injectable()
export class EnvironmentClaimWebApi extends BaseWebApi {
    public constructor(
        private httpClient: HttpClient,
        @Inject(API_URL) private apiUrl: string,
    ) { 
        super();
    }

    public getPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IEnvironmentClaim>> {
        return this.httpClient.get<IPaginatedResult<IEnvironmentClaim>>(this.apiUrl + "/EnvironmentClaim?" + this.getSieveQueryString(paginatedRequestParameters), { withCredentials: true });
    }

    public create$(environmentClaim: IEnvironmentClaim): Observable<number> {
        return this.httpClient.post<number>(this.apiUrl + "/EnvironmentClaim", environmentClaim, { withCredentials: true });
    }

    public update$(id: number, environmentClaim: IEnvironmentClaim): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/EnvironmentClaim/" + id, environmentClaim, { withCredentials: true });
    }

    public delete$(id: number): Observable<void> {
        return this.httpClient.delete<void>(this.apiUrl + "/EnvironmentClaim/" + id, { withCredentials: true });
    }

    public startEnvironmentClaim$(startEnvironmentClaimParameters: IStartEnvironmentClaimParameters): Observable<number> {
        return this.httpClient.post<number>(this.apiUrl + "/EnvironmentClaim/StartEnvironmentClaim", startEnvironmentClaimParameters, { withCredentials: true });
    }

    public startEnvironmentUnclaim$(id: number): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/EnvironmentClaim/" + id + "/StartEnvironmentUnclaim", null, { withCredentials: true });
    }

    public startEnvironmentResync$(id: number): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/EnvironmentClaim/" + id + "/StartEnvironmentResync", null, { withCredentials: true });
    }

    public updateBuildVersion$(id: number, buildVersion: string): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/EnvironmentClaim/" + id + "/UpdateBuildVersion", JSON.stringify(buildVersion), { 
            withCredentials: true,
            headers: new HttpHeaders({
                "content-type": "application/json",
            }),
        });
    }
    
    public setJenkinsJobSuccessfulForEnvironment$(environmentNumber: number): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/EnvironmentClaim/SetJenkinsJobSuccessfulForEnvironment/" + environmentNumber, null, {withCredentials: true });
    }
}
