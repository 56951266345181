import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BaseComponent } from "rabobank-utilities";

@Component({
    selector: "governance-grfd1-help-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class HelpModalComponent extends BaseComponent {
    public constructor(
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {

    }

    public initializeModal(): void {

    }
}
