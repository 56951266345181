import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponent, IPaginatedFilterTableConfiguration, IPaginatedResult, PaginatedFilterTableComponent } from "rabobank-utilities";
import { combineLatest, Observable, of, ReplaySubject } from "rxjs";
import { filter, map, mergeMap, startWith, take, takeUntil } from "rxjs/operators";
import { IExtendedClaimableEnvironmentType } from "src/app/models/IExtendedClaimableEnvironmentType";
import { IExtendedUser } from "src/app/models/IExtendedUser";
import { PaginatedFilterTableConfigurationService } from "src/app/services/PaginatedFilterTableConfigurationService";
import { ICurrentEnvironment } from "./ICurrentEnvironment";
import { IDataProvider } from "./IDataProvider";

@Component({
    selector: "governance-grfd1-current-environment-claim-overview",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class CurrentEnvironmentClaimOverviewComponent extends BaseComponent {
    @Input() public claimableEnvironmentType$: Observable<IExtendedClaimableEnvironmentType>;
    @Input() public dataProvider: IDataProvider;

    @Output("claimEnvironment") public claimEnvironmentEventEmitter: EventEmitter<ICurrentEnvironment> = new EventEmitter<ICurrentEnvironment>();
    @Output("unclaimEnvironment") public unclaimEnvironmentEventEmitter: EventEmitter<ICurrentEnvironment> = new EventEmitter<ICurrentEnvironment>();
    @Output("resyncEnvironment") public resyncEnvironmentEventEmitter: EventEmitter<ICurrentEnvironment> = new EventEmitter<ICurrentEnvironment>();
    @Output("showJenkinsInformation") public showJenkinsInformationEventEmitter: EventEmitter<ICurrentEnvironment> = new EventEmitter<ICurrentEnvironment>();
    @Output("showAzureDevOpsWorkItemInformation") public showAzureDevOpsWorkItemInformationEventEmitter: EventEmitter<ICurrentEnvironment> = new EventEmitter<ICurrentEnvironment>();
    @Output("updateBuildVersion") public updateBuildVersionEventEmitter: EventEmitter<ICurrentEnvironment> = new EventEmitter<ICurrentEnvironment>();

    @ViewChild(PaginatedFilterTableComponent) paginatedFilterTableComponent: PaginatedFilterTableComponent<any>;
    @ViewChild("tableBodyCellClaimUnclaimButton", { static: true }) tableBodyCellClaimUnclaimButtonTemplateRef: TemplateRef<any>;
    @ViewChild("tableBodyCellResyncButton", { static: true }) tableBodyCellResyncButtonTemplateRef: TemplateRef<any>;
    @ViewChild("tableBodyCellJenkinsInformationButton", { static: true }) tableBodyCellJenkinsInformationButtonTemplateRef: TemplateRef<any>;
    @ViewChild("tableBodyCellAzureDevOpsWorkItemInformationButton", { static: true }) tableBodyCellAzureDevOpsWorkItemInformationButtonTemplateRef: TemplateRef<any>;
    @ViewChild("tableBodyCellClaimableEnvironmentNumber", { static: true }) tableBodyCellClaimableEnvironmentNumberTemplateRef: TemplateRef<any>;
    @ViewChild("tableBodyCellBuildVersion", { static: true }) tableBodyCellBuildVersionTemplateRef: TemplateRef<any>;
    @ViewChild("tableBodyCellAzureDevOpsWorkItemId", { static: true }) tableBodyCellAzureDevOpsWorkItemIdTemplateRef: TemplateRef<any>;

    public user$: ReplaySubject<IExtendedUser> = new ReplaySubject<IExtendedUser>(1);
    public currentEnvironmentList$: ReplaySubject<ICurrentEnvironment[]> = new ReplaySubject<ICurrentEnvironment[]>(1);

    public paginatedFilterTableConfiguration: IPaginatedFilterTableConfiguration<ICurrentEnvironment>;

    public paginatedResult$: ReplaySubject<IPaginatedResult<ICurrentEnvironment>> = new ReplaySubject<IPaginatedResult<ICurrentEnvironment>>(1);

    public constructor(
        private paginatedFilterTableConfigurationService: PaginatedFilterTableConfigurationService,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    let paginatedFilterTableConfiguration = this.paginatedFilterTableConfigurationService.getCurrentEnvironmentPaginatedFilterTableConfiguration();

                    paginatedFilterTableConfiguration.tableConfiguration.bodyRowCssClassListSelector$ = (item) => {
                        return this.user$
                            .pipe(map((user) => {
                                let classList: string[] = [];

                                if (item.extendedClaimableEnvironment.forAdministratorOnly == true && user.isAdministrator == false) {
                                    classList.push("text-muted");
                                }

                                if (item.extendedEnvironmentClaim != null) {
                                    let latestEnvironmentClaimResync = item.extendedEnvironmentClaim.environmentClaimResyncList.length > 0 ? item.extendedEnvironmentClaim.environmentClaimResyncList.sort((a, b) => a.resyncDate > b.resyncDate ? -1 : (a.resyncDate < b.resyncDate ? 1 : 0))[0] : null;

                                    if (( //If the unclaim job hasn't been started, and the claim job finished successfully
                                            item.extendedEnvironmentClaim.unclaimBuildJobNumber == null 
                                            && item.extendedEnvironmentClaim.claimBuildJobSuccessful === true
                                        ) 
                                        || ( //If the unclaim job has been started, and it finished successfully
                                            item.extendedEnvironmentClaim.unclaimBuildJobNumber != null 
                                            && item.extendedEnvironmentClaim.unclaimBuildJobSuccessful === true
                                        )
                                        || ( //If there is a latest resync job, and it finished successfully
                                            latestEnvironmentClaimResync != null
                                            && latestEnvironmentClaimResync.resyncBuildJobSuccessful === true
                                        )
                                    ) {
                                        classList.push("build-successful");
                                    }

                                    if (( //If the unclaim job hasn't been started yet, and the claim job didn't finish successfully
                                            item.extendedEnvironmentClaim.unclaimBuildJobNumber == null 
                                            && item.extendedEnvironmentClaim.claimBuildJobSuccessful === false
                                        ) 
                                        || ( //If the unclaim job has been started, but it didn't finish successfully
                                            item.extendedEnvironmentClaim.unclaimBuildJobNumber != null 
                                            && item.extendedEnvironmentClaim.unclaimBuildJobSuccessful === false
                                        )
                                        || ( //If there is a latest resync job, and it didn't finish successfully
                                            latestEnvironmentClaimResync != null
                                            && latestEnvironmentClaimResync.resyncBuildJobSuccessful === false
                                        )
                                    ) {
                                        classList.push("build-failed");
                                    }

                                    if (( //If the claim job has been started, but it hasn't finished yet
                                            item.extendedEnvironmentClaim.claimBuildJobNumber != null 
                                            && item.extendedEnvironmentClaim.claimBuildJobSuccessful === null
                                        ) 
                                        || ( //If the unclaim job has been started, but it hasn't finished yet
                                            item.extendedEnvironmentClaim.unclaimBuildJobNumber != null 
                                            && item.extendedEnvironmentClaim.unclaimBuildJobSuccessful === null
                                        )
                                        || ( //If there is a latest resync job, and it hasn't finished yet
                                            latestEnvironmentClaimResync != null
                                            && latestEnvironmentClaimResync.resyncBuildJobNumber != null
                                            && latestEnvironmentClaimResync.resyncBuildJobSuccessful === null
                                        )
                                    ) {
                                        classList.push("build-building");
                                    }
                                }

                                return classList;
                            }));

                    };

                    //Insert the resync button column at the start of the list
                    paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.splice(0, 0, {
                        headerCellValue: "Resync",
                        bodyCellTemplateRef: this.tableBodyCellResyncButtonTemplateRef,
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                    });

                    //Insert the claim/unclaim button column at the very start, before the resync button column we just added
                    paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.splice(0, 0, {
                        headerCellValue: "",
                        bodyCellTemplateRef: this.tableBodyCellClaimUnclaimButtonTemplateRef,
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                    });

                    //Insert the jenkins button column before the claimable environment number column
                    paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.splice(paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.findIndex(x => x.headerCellValue == "Claimable environment number"), 0, {
                        headerCellValue: "Jenkins",
                        bodyCellTemplateRef: this.tableBodyCellJenkinsInformationButtonTemplateRef,
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                    });

                    //Insert the claimable environment number column before the claim crossvista track template button column 
                    paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.splice(paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.findIndex(x => x.headerCellValue == "Claim CrossVista track template"), 0, {
                        headerCellValue: "Claimable environment number",
                        bodyCellTemplateRef: this.tableBodyCellClaimableEnvironmentNumberTemplateRef,
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                        sortPropertyName: "extendedClaimableEnvironment.number",
                    });

                    //Insert the azure devops button column before the claimable environment number column, meaning it comes after the jenkins button column
                    paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.splice(paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.findIndex(x => x.headerCellValue == "Claimable environment number"), 0, {
                        headerCellValue: "Azure DevOps",
                        bodyCellTemplateRef: this.tableBodyCellAzureDevOpsWorkItemInformationButtonTemplateRef,
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                    });

                    //Insert the build version column before the claimed by column
                    paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.splice(paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.findIndex(x => x.headerCellValue == "Claimed by"), 0, {
                        headerCellValue: "Build version",
                        bodyCellTemplateRef: this.tableBodyCellBuildVersionTemplateRef,
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle", "text-center"]),
                        sortPropertyName: "extendedEnvironmentClaim.buildVersion",
                    });

                    //Insert the work item id column before the work item assignee column
                    paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.splice(paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.findIndex(x => x.headerCellValue == "Work item assignee"), 0, {
                        headerCellValue: "Work item id",
                        bodyCellTemplateRef: this.tableBodyCellAzureDevOpsWorkItemIdTemplateRef,
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle", "text-nowrap"]),
                        sortPropertyName: "extendedEnvironmentClaim.azureDevOpsWorkItem.id",
                    });

                    this.paginatedFilterTableConfiguration = paginatedFilterTableConfiguration;

                    combineLatest([
                        this.claimableEnvironmentType$,
                        this.dataProvider.environmentClaimListUpdated$.pipe(startWith([null])),
                        this.dataProvider.claimableEnvironmentListUpdated$.pipe(startWith([null])),
                    ])
                        .pipe(mergeMap(([
                            claimableEnvironmentType,
                        ]) => {
                            return this.dataProvider.getCurrentEnvironmentList$(claimableEnvironmentType.identifier);
                        }))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (currentEnvironmentList) => {
                                this.currentEnvironmentList$.next(currentEnvironmentList);
                            },
                        });

                    combineLatest([
                        this.paginatedFilterTableConfiguration.paginatedRequestHelper.paginatedRequestParameters$.pipe(filter(x => x != null), map(x => x!)),
                    ])
                        .pipe(mergeMap(([
                            paginatedRequestParameters,
                        ]) => {
                            return this.dataProvider.getPaginatedResult$(this.currentEnvironmentList$, paginatedRequestParameters);
                        }))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (paginatedResult) => {
                                this.paginatedResult$.next(paginatedResult);
                            },
                        });

                    combineLatest([
                        this.dataProvider.userListUpdated$.pipe(startWith([null])),
                    ])
                        .pipe(mergeMap(() => {
                            return this.dataProvider.getCurrentUser$();
                        }))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (user) => {
                                this.user$.next(user);
                            },
                        });
                },
            });

            this.afterViewInit$
                .pipe(takeUntil(this.onDestroy$))
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.paginatedFilterTableComponent.applyFilters();
                    },
                });
    }

    public isClaimButtonVisible$(currentEnvironment: ICurrentEnvironment): Observable<boolean> {
        return this.user$
            .pipe(map((user) => {
                //If the environment is already claimed, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim != null) {
                    return false;
                }

                //If the user is an administrator, the button IS visible
                if (user.isAdministrator == true) {
                    return true;
                }

                //If claiming is disabled for the claimable environment type, the button IS NOT visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedClaimableEnvironmentType.disableClaiming == true) {
                    return false;
                }

                //If the environment is only for administrators, the button IS NOT visible
                if (currentEnvironment.extendedClaimableEnvironment.forAdministratorOnly == true) {
                    return false;
                }

                //If the environment belongs to a project team, and the user also belongs to that project team, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam != null && currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam.id == user.extendedProjectTeam.id) {
                    return true;
                }

                //If the environment belongs to a project team, and the entire track is allowed to claim the environment, and the user belongs to the same track as the project team, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam != null && currentEnvironment.extendedClaimableEnvironment.allowEntireTrackToClaim == true && currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam.extendedTrack.id == user.extendedProjectTeam.extendedTrack.id) {
                    return true;
                }

                //If the environment doesn't belong to a project team, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam == null) {
                    return true;
                }

                //In all other cases, the button IS NOT visible
                return false;
            }));
    }

    public isUnclaimButtonVisible$(currentEnvironment: ICurrentEnvironment): Observable<boolean> {
        return this.user$
            .pipe(map((user) => {
                //If the environment is not claimed, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim == null) {
                    return false;
                }

                //If the claim job isn't finished yet, or didn't finish successfully, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim.claimBuildJobSuccessful == null || currentEnvironment.extendedEnvironmentClaim.claimBuildJobSuccessful == false) {
                    return false;
                }

                //If the unclaim job has already been started, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim.unclaimBuildJobNumber != null) {
                    return false;
                }

                //If there is a resync job currently running, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim.environmentClaimResyncList.find(x => x.resyncBuildJobSuccessful == null) != null) {
                    return false;
                }

                //If the user is an administrator, the button IS visible
                if (user.isAdministrator == true) {
                    return true;
                }

                //If claiming is disabled for the claimable environment type, the button IS NOT visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedClaimableEnvironmentType.disableClaiming == true) {
                    return false;
                }

                //If the specified environment is only for administrators, the button IS NOT visible
                if (currentEnvironment.extendedClaimableEnvironment.forAdministratorOnly == true) {
                    return false;
                }

                //If the environment belongs to a project team, and the user also belongs to that project team, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam != null && user.extendedProjectTeam.id == currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam.id) {
                    return true;
                }

                //If the environment belongs to a project team, and the entire track is allowed to claim the environment, and the user belongs to the same track as the project team, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam != null && currentEnvironment.extendedClaimableEnvironment.allowEntireTrackToClaim == true && currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam.extendedTrack.id == user.extendedProjectTeam.extendedTrack.id) {
                    return true;
                }

                //If the environment doesn't belong to a team, and the environment was claimed by the user, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam == null && currentEnvironment.extendedEnvironmentClaim.extendedUser.id == user.id) {
                    return true;
                }

                //In all other cases, the button IS NOT visible
                return false;
            }));
    }

    public isResyncButtonVisible$(currentEnvironment: ICurrentEnvironment): Observable<boolean> {
        return this.user$
            .pipe(map((user) => {
                //If the environment is not claimed, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim == null) {
                    return false;
                }

                //If the claim job isn't finished yet, or didn't finish successfully, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim.claimBuildJobSuccessful == null || currentEnvironment.extendedEnvironmentClaim.claimBuildJobSuccessful == false) {
                    return false;
                }

                //If the unclaim job has already been started, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim.unclaimBuildJobNumber != null) {
                    return false;
                }

                //If there is a resync job currently running, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim.environmentClaimResyncList.find(x => x.resyncBuildJobSuccessful == null) != null) {
                    return false;
                }

                //If the user is an administrator, the button IS visible
                if (user.isAdministrator == true) {
                    return true;
                }

                //If claiming is disabled for the claimable environment type, the button IS NOT visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedClaimableEnvironmentType.disableClaiming == true) {
                    return false;
                }

                //If the specified environment is only for administrators, the button IS NOT visible
                if (currentEnvironment.extendedClaimableEnvironment.forAdministratorOnly == true) {
                    return false;
                }

                //If the environment belongs to a project team, and the user also belongs to that project team, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam != null && user.extendedProjectTeam.id == currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam.id) {
                    return true;
                }

                //If the environment belongs to a project team, and the entire track is allowed to claim the environment, and the user belongs to the same track as the project team, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam != null && currentEnvironment.extendedClaimableEnvironment.allowEntireTrackToClaim == true && currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam.extendedTrack.id == user.extendedProjectTeam.extendedTrack.id) {
                    return true;
                }

                //If the environment doesn't belong to a team, and the environment was claimed by the user, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam == null && currentEnvironment.extendedEnvironmentClaim.extendedUser.id == user.id) {
                    return true;
                }

                //In all other cases, the button IS NOT visible
                return false;
            }));
    }

    public isUpdateBuildVersionButtonVisible$(currentEnvironment: ICurrentEnvironment): Observable<boolean> {
        return this.user$
            .pipe(map((user) => {
                //If the environment is not claimed, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim == null) {
                    return false;
                }

                //If the claim job isn't finished yet, or didn't finish successfully, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim.claimBuildJobSuccessful == null || currentEnvironment.extendedEnvironmentClaim.claimBuildJobSuccessful == false) {
                    return false;
                }

                //If the unclaim job has already been started, the button IS NOT visible
                if (currentEnvironment.extendedEnvironmentClaim.unclaimBuildJobNumber != null) {
                    return false;
                }

                //If the user is an administrator, the button IS visible
                if (user.isAdministrator == true) {
                    return true;
                }

                //If the specified environment is only for administrators, the button IS NOT visible
                if (currentEnvironment.extendedClaimableEnvironment.forAdministratorOnly == true) {
                    return false;
                }

                //If the environment belongs to a project team, and the user also belongs to that project team, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam != null && user.extendedProjectTeam.id == currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam.id) {
                    return true;
                }

                //If the environment belongs to a project team, and the entire track is allowed to claim the environment, and the user belongs to the same track as the project team, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam != null && currentEnvironment.extendedClaimableEnvironment.allowEntireTrackToClaim == true && currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam.extendedTrack.id == user.extendedProjectTeam.extendedTrack.id) {
                    return true;
                }

                //If the environment doesn't belong to a team, and the environment was claimed by the user, the button IS visible
                if (currentEnvironment.extendedClaimableEnvironment.extendedProjectTeam == null && currentEnvironment.extendedEnvironmentClaim.extendedUser.id == user.id) {
                    return true;
                }

                //In all other cases, the button IS NOT visible
                return false;
            }));
    }

    public onClaimEnvironment(currentEnvironment: ICurrentEnvironment): void {
        this.claimEnvironmentEventEmitter.emit(currentEnvironment);
    }

    public onUnclaimEnvironment(currentEnvironment: ICurrentEnvironment): void {
        this.unclaimEnvironmentEventEmitter.emit(currentEnvironment);
    }

    public onResyncEnvironment(currentEnvironment: ICurrentEnvironment): void {
        this.resyncEnvironmentEventEmitter.emit(currentEnvironment);
    }

    public onShowJenkinsInformation(currentEnvironment: ICurrentEnvironment): void {
        this.showJenkinsInformationEventEmitter.emit(currentEnvironment);
    }

    public onShowAzureDevOpsWorkItemInformation(currentEnvironment: ICurrentEnvironment): void {
        this.showAzureDevOpsWorkItemInformationEventEmitter.emit(currentEnvironment);
    }

    public onUpdateBuildVersion(currentEnvironment: ICurrentEnvironment): void {
        this.updateBuildVersionEventEmitter.emit(currentEnvironment);
    }
}
