import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { BaseComponent, IPaginatedFilterTableModalFieldTypeConfiguration } from "rabobank-utilities";
import { ReplaySubject } from "rxjs";
import { map, mergeMap, startWith, take, takeUntil } from "rxjs/operators";
import { IExtendedClaimableEnvironment } from "src/app/models/IExtendedClaimableEnvironment";
import { IExtendedCrossVistaTrackTemplate } from "src/app/models/IExtendedCrossVistaTrackTemplate";
import { IStartEnvironmentClaimParameters } from "src/app/services/GovernanceGrfd1Api";
import { PaginatedFilterTableConfigurationService } from "src/app/services/PaginatedFilterTableConfigurationService";
import { buildVersionValidator } from "src/app/services/Validators";
import { IDataProvider } from "./IDataProvider";

@Component({
    selector: "governance-grfd1-start-environment-claim-form",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class StartEnvironmentClaimFormComponent extends BaseComponent {
    @Input() dataProvider: IDataProvider;
    @Input() public claimableEnvironmentId: number;
    @Input() public claimableEnvironmentTypeId: number;
    @Input() public requireBuildVersion: boolean;

    @Output("formSubmit") public formSubmitEventEmitter: EventEmitter<IStartEnvironmentClaimParameters> = new EventEmitter<IStartEnvironmentClaimParameters>();

    public formGroup: FormGroup = new FormGroup({});
    public model = {} as {} as IStartEnvironmentClaimParameters;
    public formlyFormOptions: FormlyFormOptions = {};
    public formlyFieldConfigList: FormlyFieldConfig[];

    private crossVistaTrackTemplateList$: ReplaySubject<IExtendedCrossVistaTrackTemplate[]> = new ReplaySubject<IExtendedCrossVistaTrackTemplate[]>(1);
    private crossVistaBaselineList$: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private paginatedFilterTableConfigurationService: PaginatedFilterTableConfigurationService,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.dataProvider.crossVistaTrackTemplateListUpdated$
                        .pipe(startWith([null]))
                        .pipe(takeUntil(this.onDestroy$))
                        .pipe(mergeMap(() => {
                            return this.dataProvider.getCrossVistaTrackTemplateList$();
                        }))
                        .subscribe({
                            next: (crossVistaTrackTemplateList) => {
                                this.crossVistaTrackTemplateList$.next(crossVistaTrackTemplateList);
                            },
                        });

                    //Only try retrieving the crossvista baseline list when a build version is required
                    if (this.requireBuildVersion == true) {
                        this.dataProvider.getCrossVistaBaselineList$(this.claimableEnvironmentTypeId)
                            .pipe(takeUntil(this.onDestroy$))
                            .pipe(take(1))
                            .subscribe({
                                next: (crossVistaBaselineList) => {
                                    this.crossVistaBaselineList$.next(crossVistaBaselineList);
                                },
                            });
                    }
                    else {
                        this.crossVistaBaselineList$.next([]);
                    }

                    this.formlyFieldConfigList = [
                        {
                            key: ["claimableEnvironmentId"],
                            type: "paginated-filter-table-modal",
                            templateOptions: {
                                label: "Claimable environment",
                                type: "number",
                                required: true,
                                readonly: true,
                                paginatedFilterTableModalFieldTypeConfiguration: {
                                    modalTitle: "Select a claimable environment",

                                    modalConfig: {
                                        class: "col-12 col-md-8",
                                        ignoreBackdropClick: true,
                                    },

                                    paginatedFilterTableConfiguration: this.paginatedFilterTableConfigurationService.getClaimableEnvironmentPaginatedFilterTableConfiguration(),

                                    dataProvider: {
                                        getPaginatedResult$: (paginatedRequestParameters) => this.dataProvider.getClaimableEnvironmentPaginatedResult$(paginatedRequestParameters),
                                        listUpdated$: this.dataProvider.claimableEnvironmentListUpdated$,
                                    },

                                    getItemFromValue$: (id) => this.dataProvider.getClaimableEnvironmentFromValue$(id),

                                    itemValueSelector: (item) => item?.id,

                                    itemLabelSelector: (item) => item != null ? (item.extendedClaimableEnvironmentType.name + " - " + item.number) : "No claimable environment selected",
                                } as IPaginatedFilterTableModalFieldTypeConfiguration<IExtendedClaimableEnvironment, number>,
                            },
                        },

                        {
                            key: ["azureDevOpsWorkItemId"],
                            type: "input",
                            templateOptions: {
                                label: "Azure DevOps work item id",
                                placeholder: "Azure DevOps work item id",
                                type: "number",
                                min: 0,
                                required: true,
                            },
                        },

                        {
                            key: ["claimCrossVistaTrackTemplate"],
                            type: "checkbox",
                            templateOptions: {
                                label: "Claim CrossVista track template",
                                required: true,
                            },
                        },

                        {
                            key: ["crossVistaTrackTemplateId"],
                            type: "select",
                            templateOptions: {
                                label: "CrossVista track template",
                                options: this.crossVistaTrackTemplateList$,
                                valueProp: (x: IExtendedCrossVistaTrackTemplate) => x.id,
                                labelProp: (x: IExtendedCrossVistaTrackTemplate) => x.name,
                            },
                            expressionProperties: {
                                "templateOptions.required": "model.claimCrossVistaTrackTemplate == true",
                            },
                            hideExpression: "model.claimCrossVistaTrackTemplate == false",
                        },

                        {
                            key: ["buildVersion"],
                            type: "select",
                            templateOptions: {
                                label: "Build version",
                                options: this.crossVistaBaselineList$.pipe(map(x => x.slice(-10).reverse())), //Get the last 10 items from the list, which should be the most recent build numbers, and then reverse the list so the most recent one is on top
                                valueProp: (x: string) => x,
                                labelProp: (x: string) => x,
                                required: this.requireBuildVersion,
                            },
                            validators: {
                                buildVersion: buildVersionValidator,
                            },
                            hide: this.requireBuildVersion == false,
                        },

                        // {
                        //     key: ["buildVersion"],
                        //     type: "input",
                        //     templateOptions: {
                        //         label: "Build version",
                        //         placeholder: "Build version",
                        //         description: "Unsure of what to enter for build version? Go to the Environment Information dashboard (link is on the product hub), take the \"Environment build version\" value of acceptance (for bugfix) or production (for hotfix), and then subtract 1. For example, if the environment build version shows 128.0.0, enter 127.0.0.",
                        //         type: "text",
                        //         maxLength: 255,
                        //         required: this.requireBuildVersion,
                        //     },
                        //     validators: {
                        //         buildVersion: buildVersionValidator,
                        //     },
                        //     hide: this.requireBuildVersion == false,
                        // },
                    ];
                },
            });

        this.afterViewInit$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    this.formGroup.controls.claimableEnvironmentId.setValue(this.claimableEnvironmentId);
                    this.formGroup.controls.claimCrossVistaTrackTemplate.setValue(true);

                    //FormlyFormOptions isn't initialized until AfterViewInit. pdate the initial value of the form so the claimable environment id can't be emptied by resetting the form
                    this.formlyFormOptions.updateInitialValue();

                    this.changeDetectorRef.detectChanges();
                },
            });
    }

    public onFormSubmit(): void {
        if (this.formGroup.valid) {
            this.formSubmitEventEmitter.emit(this.model);
        }
    }
}