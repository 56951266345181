import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { IAppState } from "./IAppState";
import { navbarReducer } from "./Navbar/navbarReducer";
import { userInformationReducer } from "./UserInformation/userInformationReducer";


export const reducers: ActionReducerMap<IAppState, any> = {
    navbar: navbarReducer,
    userInformation: userInformationReducer,
};

export function logger(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
    return function(state: IAppState | undefined, action: any): IAppState {
        console.log("state", state);
        console.log("action", action);
        
        return reducer(state, action);
    }
}

export const metaReducers: MetaReducer<IAppState>[] = !environment.production 
    ? [logger] 
    : [];