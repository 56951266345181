import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { IExtendedUser } from "src/app/models/IExtendedUser";
import { IAppState } from "../IAppState";
import { ISetUserAction } from "./UserInformationActionTypes";
import { UserInformationActionTypesEnum } from "./UserInformationActionTypesEnum";

@Injectable()
export class UserInformationActions {
    public constructor(
        private store: Store<IAppState>,
    ) { }
    
    public setUser(user: IExtendedUser): void {
        this.store.dispatch<ISetUserAction>({
            type: UserInformationActionTypesEnum.SetUser,
            payload: {
                user: user,
            },
        });
    }
}
