import { IPaginatedRequestParameters } from "rabobank-utilities";

export abstract class BaseWebApi {
    protected getSieveQueryString(paginatedRequestParameters: IPaginatedRequestParameters): string {
        let queryStringPartList = [];

        queryStringPartList.push("page=" + paginatedRequestParameters.page);
        queryStringPartList.push("pageSize=" + paginatedRequestParameters.itemsPerPage);
        
        if (paginatedRequestParameters.whereConditionList != null && paginatedRequestParameters.whereConditionList.length > 0) {
            queryStringPartList.push("filters=" + paginatedRequestParameters.whereConditionList.map(x => x.propertyName + x.operator + x.value).join(","));
        }

        if (paginatedRequestParameters.sortConditionList != null && paginatedRequestParameters.sortConditionList.length > 0) {
            queryStringPartList.push("sorts=" + paginatedRequestParameters.sortConditionList.map(x => (x.ascending == true ? "" : "-") + x.propertyName).join(","));
        }

        return queryStringPartList.join("&");
    }
}