<div class="modal-header">
    <h5 class="modal-title">Azure DevOps work item information for environment {{currentEnvironment?.extendedClaimableEnvironment.number}}</h5>
    <fa-icon class="btn close"
             [icon]="['fas', 'times']"
             size="lg"
             (click)="bsModalRef.hide()"></fa-icon>
</div>
<div class="modal-body">
    <governance-grfd1-azure-devops-work-item-information *ngIf="currentEnvironment != null"
                                                   [dataProvider]="dataProvider"
                                                   [environmentClaimId]="currentEnvironment.extendedEnvironmentClaim.id"></governance-grfd1-azure-devops-work-item-information>
</div>