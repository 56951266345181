<div class="modal-header">
    <h5 class="modal-title">Project team</h5>
    <fa-icon class="btn close"
             [icon]="['fas', 'times']"
             size="lg"
             (click)="bsModalRef.hide()"></fa-icon>
</div>
<div class="modal-body">
    <governance-grfd1-project-team-form [dataProvider]="dataProvider"
                                  (formSubmit)="onFormSubmit($event)"></governance-grfd1-project-team-form>
</div>