import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginatedRequestParameters, IPaginatedResult } from "rabobank-utilities";
import { Observable } from "rxjs";
import { API_URL } from "../InjectionTokens";
import { IUser } from "../models/IUser";
import { BaseWebApi } from "./BaseWebApi";

@Injectable()
export class UserWebApi extends BaseWebApi {
    public constructor(
        private httpClient: HttpClient,
        @Inject(API_URL) private apiUrl: string,
    ) { 
        super();
    }

    public getPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IUser>> {
        return this.httpClient.get<IPaginatedResult<IUser>>(this.apiUrl + "/User?" + this.getSieveQueryString(paginatedRequestParameters), { withCredentials: true });
    }

    public create$(user: IUser): Observable<number> {
        return this.httpClient.post<number>(this.apiUrl + "/User", user, { withCredentials: true });
    }

    public update$(id: number, user: IUser): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/User/" + id, user, { withCredentials: true });
    }

    public delete$(id: number): Observable<void> {
        return this.httpClient.delete<void>(this.apiUrl + "/User/" + id, { withCredentials: true });
    }

    public getUser$(): Observable<IUser> {
        return this.httpClient.get<IUser>(this.apiUrl + "/User/GetUser", { withCredentials: true });
    }

    public createCurrentUser$(projectTeamId: number): Observable<number> {
        return this.httpClient.post<number>(this.apiUrl + "/User/CreateCurrentUser", projectTeamId, { withCredentials: true });
    }
}
