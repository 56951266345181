import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { Observable } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceGrfd1WebApi, ITrack } from "src/app/services/GovernanceGrfd1Api";
import { TrackFormComponent } from "../../forms/track-form/component";
import { IDataProvider } from "../../forms/track-form/IDataProvider";

@Component({
    selector: "governance-grfd1-track-edit-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class TrackEditModalComponent extends BaseComponent {
    @Output("recordSaved") public recordSavedEventEmitter: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild(TrackFormComponent) public trackFormComponent: TrackFormComponent;

    public dataProvider: IDataProvider = {
        getTrack$: (id) => {
            return this.extendedObjectApiCallHelper.getTrack$(id);
        },
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceGrfd1WebApi: GovernanceGrfd1WebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {

    }

    public initializeModal(id: number | null): void {
        if (id != null) {
            this.trackFormComponent.loadRecord(id);
        }
    }

    public onFormSubmit(track: ITrack): void {
        let loadingModalRef = this.commonModalService.showLoadingModal("Saving record...");

        let call: Observable<number>;

        if (track.id != null) {
            call = this.governanceGrfd1WebApi.track.update$(track.id, track)
                .pipe(map(x => track.id));
        }
        else {
            call = this.governanceGrfd1WebApi.track.create$(track);
        }

        this.apiCallHelper.call$(call)
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (id) => {
                    this.recordSavedEventEmitter.emit(id);

                    this.bsModalRef.hide();
                },
            })
            .add(() => loadingModalRef.hide());
    }
}
