import { Inject, Injectable } from "@angular/core";
import { HubConnectionBuilder, IHttpConnectionOptions } from "@microsoft/signalr";
import { GovernanceGrfd1DataHub } from "./hubs/GovernanceGrfd1DataHub";
import { API_URL, HUB_HTTP_CONNECTION_OPTIONS } from "./InjectionTokens";

@Injectable()
export class GovernanceGrfd1Hub {
    public governanceGrfd1Data: GovernanceGrfd1DataHub;
    
    public constructor(
        hubConnectionBuilder: HubConnectionBuilder,
        @Inject(API_URL) apiUrl: string,
        @Inject(HUB_HTTP_CONNECTION_OPTIONS) hubHttpConnectionOptions: IHttpConnectionOptions,
    ) {
        this.governanceGrfd1Data = new GovernanceGrfd1DataHub(hubConnectionBuilder, apiUrl, hubHttpConnectionOptions);
    }
}
