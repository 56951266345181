import { Injectable } from "@angular/core";
import { DateStringHelper, IPaginatedFilterTableConfiguration, PaginatedRequestHelper } from "rabobank-utilities";
import { of } from "rxjs";
import { ICurrentEnvironment } from "../components/home/manage/current-environment-claim-overview/ICurrentEnvironment";
import { IExtendedClaimableEnvironment } from "../models/IExtendedClaimableEnvironment";
import { IExtendedClaimableEnvironmentType } from "../models/IExtendedClaimableEnvironmentType";
import { IExtendedCrossVistaTrackTemplate } from "../models/IExtendedCrossVistaTrackTemplate";
import { IExtendedEnvironmentClaim } from "../models/IExtendedEnvironmentClaim";
import { IExtendedProjectTeam } from "../models/IExtendedProjectTeam";
import { IExtendedTrack } from "../models/IExtendedTrack";
import { IExtendedUser } from "../models/IExtendedUser";

@Injectable()
export class PaginatedFilterTableConfigurationService {
    public constructor(
        private dateStringHelper: DateStringHelper,
    ) {

    }

    public getCurrentEnvironmentPaginatedFilterTableConfiguration(): IPaginatedFilterTableConfiguration<ICurrentEnvironment> {
        return {
            paginatedRequestHelper: new PaginatedRequestHelper(10000, [
                { propertyName: "extendedClaimableEnvironment.number", ascending: true },
            ]),

            showNumberOfItemsFound: true,

            tableConfiguration: {
                tableColumnList: [
                    {
                        headerCellValue: "Track name",
                        bodyCellValueSelector$: (item) => of(item.extendedClaimableEnvironment.extendedProjectTeam != null ? item.extendedClaimableEnvironment.extendedProjectTeam.extendedTrack.name : ""),
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                        sortPropertyName: "extendedClaimableEnvironment.extendedProjectTeam.extendedTrack.name",
                    },

                    {
                        headerCellValue: "Project team name",
                        bodyCellValueSelector$: (item) => of(item.extendedClaimableEnvironment.extendedProjectTeam != null ? item.extendedClaimableEnvironment.extendedProjectTeam.name : ""),
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                        sortPropertyName: "extendedClaimableEnvironment.extendedProjectTeam.name",
                    },

                    {
                        headerCellValue: "Claim CrossVista track template",
                        bodyCellValueSelector$: (item) => of(item.extendedEnvironmentClaim != null ? (item.extendedEnvironmentClaim.claimCrossVistaTrackTemplate === true ? "Yes" : (item.extendedEnvironmentClaim.claimCrossVistaTrackTemplate === false ? "No" : "")) : ""),
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                        sortPropertyName: "extendedEnvironmentClaim.claimCrossVistaTrackTemplate",
                    },

                    {
                        headerCellValue: "CrossVista track template",
                        bodyCellValueSelector$: (item) => of(item.extendedEnvironmentClaim != null ? (item.extendedEnvironmentClaim.extendedCrossVistaTrackTemplate?.name ?? "") : ""),
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                        sortPropertyName: "extendedEnvironmentClaim.extendedCrossVistaTrackTemplate.name",
                    },

                    {
                        headerCellValue: "Claimed by",
                        bodyCellValueSelector$: (item) => of(item.extendedEnvironmentClaim != null ? (item.extendedEnvironmentClaim.extendedUser.emailAddress + " (" + item.extendedEnvironmentClaim.extendedUser.extendedProjectTeam.extendedTrack.name + " - " + item.extendedEnvironmentClaim.extendedUser.extendedProjectTeam.name + ")") : ""),
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                        sortPropertyName: "extendedEnvironmentClaim.extendedUser.emailAddress",
                    },

                    {
                        headerCellValue: "Claim duration",
                        bodyCellValueSelector$: (item) => {
                            if (item.extendedEnvironmentClaim == null) {
                                return of("");
                            }

                            let claimDurationInMilliseconds = new Date().getTime() - new Date(item.extendedEnvironmentClaim.claimDateStart).getTime();

                            let remainingMilliseconds = claimDurationInMilliseconds;

                            let days = Math.floor(remainingMilliseconds / 86400000);
                            remainingMilliseconds = remainingMilliseconds % 86400000;

                            let hours = Math.floor(remainingMilliseconds / 3600000);
                            remainingMilliseconds = remainingMilliseconds % 3600000;

                            let minutes = Math.floor(remainingMilliseconds / 60000);
                            remainingMilliseconds = remainingMilliseconds % 60000;

                            let durationString: string;

                            if (days > 0) {
                                durationString = days + " " + (days == 1 ? "day" : "days");
                            }
                            else if (hours > 0) {
                                durationString = hours + " " + (hours == 1 ? "hour" : "hours");
                            }
                            else {
                                durationString = minutes + " " + (minutes == 1 ? "minute" : "minutes");
                            }

                            return of(this.dateStringHelper.getDateTimeString(new Date(item.extendedEnvironmentClaim.claimDateStart)) + " (" + durationString + " ago)");
                        },
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                        sortPropertyName: "extendedEnvironmentClaim.claimDateStart",
                    },

                    {
                        headerCellValue: "Work item assignee",
                        bodyCellValueSelector$: (item) => of(item.extendedEnvironmentClaim != null ? item.extendedEnvironmentClaim.azureDevOpsWorkItem.assigneeEmailAddress : ""),
                        headerCellCssClassList: ["align-middle"],
                        bodyCellCssClassListSelector$: (item) => of(["align-middle"]),
                        sortPropertyName: "extendedEnvironmentClaim.azureDevOpsWorkItem.assigneeEmailAddress",
                    },
                ],
            },

            paginationConfiguration: {
                showIfOnlyOnePage: false,
                boundaryLinks: true,
                maxSize: 7,
            },

            filterConfiguration: {
                isCollapsed: true,
                model: {},
                formlyFormOptions: {},

                formlyFieldConfigList: [
                    {
                        fieldGroupClassName: "row",
                        fieldGroup: [
                            {
                                key: ["extendedClaimableEnvironment.extendedProjectTeam.extendedTrack.name"],
                                type: "input",
                                className: "col-3",
                                templateOptions: {
                                    label: "Track name",
                                    placeholder: "Track name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["extendedClaimableEnvironment.extendedProjectTeam.name"],
                                type: "input",
                                className: "col-3",
                                templateOptions: {
                                    label: "Project team name",
                                    placeholder: "Project team name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["extendedClaimableEnvironment.number"],
                                type: "input",
                                className: "col-3",
                                templateOptions: {
                                    label: "Claimable environment number",
                                    placeholder: "Claimable environment number",
                                    type: "number",
                                    required: false,
                                },
                            },

                            {
                                key: ["extendedEnvironmentClaim.claimCrossVistaTrackTemplate"],
                                type: "select",
                                className: "col-3",
                                templateOptions: {
                                    label: "Claim CrossVista track template",
                                    required: false,
                                    options: [
                                        { value: null, label: "" },
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ],
                                    valueProp: "value",
                                    labelProp: "label",
                                },
                            },

                            {
                                key: ["extendedEnvironmentClaim.buildVersion"],
                                type: "input",
                                className: "col-3",
                                templateOptions: {
                                    label: "Build version",
                                    placeholder: "Build version",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["extendedEnvironmentClaim.extendedUser.emailAddress"],
                                type: "input",
                                className: "col-3",
                                templateOptions: {
                                    label: "Claimed by",
                                    placeholder: "Claimed by ",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["extendedEnvironmentClaim.azureDevOpsWorkItem.id"],
                                type: "input",
                                className: "col-3",
                                templateOptions: {
                                    label: "Azure DevOps work item id",
                                    placeholder: "Claimed by ",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["extendedEnvironmentClaim.azureDevOpsWorkItem.assigneeEmailAddress"],
                                type: "input",
                                className: "col-3",
                                templateOptions: {
                                    label: "Assignee",
                                    placeholder: "Assignee ",
                                    type: "text",
                                    required: false,
                                },
                            },
                        ],
                    },
                ],

                whereConditionConfigurationList: [
                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "extendedClaimableEnvironment.extendedProjectTeam.extendedTrack.name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "extendedClaimableEnvironment.extendedProjectTeam.name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "extendedClaimableEnvironment.number",
                            operator: "==",
                            value: null,
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "extendedEnvironmentClaim.claimCrossVistaTrackTemplate",
                            operator: "==",
                            value: null,
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "extendedEnvironmentClaim.buildVersion",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "extendedEnvironmentClaim.extendedUser.emailAddress",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "extendedEnvironmentClaim.azureDevOpsWorkItem.id",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "extendedEnvironmentClaim.azureDevOpsWorkItem.assigneeEmailAddress",
                            operator: "@=*",
                            value: "",
                        },
                    },

                ],
            },
        };
    }

    public getClaimableEnvironmentPaginatedFilterTableConfiguration(): IPaginatedFilterTableConfiguration<IExtendedClaimableEnvironment> {
        return {
            paginatedRequestHelper: new PaginatedRequestHelper(15, [
                { propertyName: "ClaimableEnvironmentType.Name", ascending: false },
                { propertyName: "Number", ascending: false },
            ]),

            showNumberOfItemsFound: true,

            tableConfiguration: {
                tableColumnList: [
                    {
                        headerCellValue: "Claimable environment type name",
                        bodyCellValueSelector$: (item) => of(item.extendedClaimableEnvironmentType.name),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ClaimableEnvironmentType.Name",
                    },

                    {
                        headerCellValue: "Track name",
                        bodyCellValueSelector$: (item) => of(item.extendedProjectTeam != null ? item.extendedProjectTeam.extendedTrack.name : ""),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ProjectTeam.Track.Name",
                    },

                    {
                        headerCellValue: "Project team name",
                        bodyCellValueSelector$: (item) => of(item.extendedProjectTeam != null ? item.extendedProjectTeam.name : ""),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ProjectTeam.Name",
                    },

                    {
                        headerCellValue: "Allow entire track to claim",
                        bodyCellValueSelector$: (item) => of(item.allowEntireTrackToClaim === true ? "Yes" : (item.allowEntireTrackToClaim === false ? "No" : "")),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ClaimCrossVistaTrackTemplate",
                    },

                    {
                        headerCellValue: "Number",
                        bodyCellValueSelector$: (item) => of(item.number.toString()),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "Number",
                    },

                    {
                        headerCellValue: "Label",
                        bodyCellValueSelector$: (item) => of(item.label),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "Label",
                    },

                    {
                        headerCellValue: "Build version schema name",
                        bodyCellValueSelector$: (item) => of(item.buildVersionSchemaName),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "BuildVersionSchemaName",
                    },

                    {
                        headerCellValue: "NexGen schema name",
                        bodyCellValueSelector$: (item) => of(item.nexGenSchemaName),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "NexGenSchemaName",
                    },

                    {
                        headerCellValue: "Target PowerCenter repository",
                        bodyCellValueSelector$: (item) => of(item.targetPowerCenterRepository),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "TargetPowerCenterRepository",
                    },

                    {
                        headerCellValue: "Target HANA schema name",
                        bodyCellValueSelector$: (item) => of(item.targetHanaSchemaName),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "TargetHanaSchemaName",
                    },

                    {
                        headerCellValue: "HANA schema name",
                        bodyCellValueSelector$: (item) => of(item.hanaSchemaName),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "HanaSchemaName",
                    },

                    {
                        headerCellValue: "For administrators only",
                        bodyCellValueSelector$: (item) => of(item.forAdministratorOnly === true ? "Yes" : (item.forAdministratorOnly === false ? "No" : "")),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ForAdministratorOnly",
                    },

                    {
                        headerCellValue: "Bitbucket branch prefix",
                        bodyCellValueSelector$: (item) => of(item.bitbucketBranchPrefix),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "BitbucketBranchPrefix",
                    },
                ],
            },

            paginationConfiguration: {
                showIfOnlyOnePage: false,
                boundaryLinks: true,
                maxSize: 7,
            },

            filterConfiguration: {
                isCollapsed: true,
                model: {},
                formlyFormOptions: {},

                formlyFieldConfigList: [
                    {
                        fieldGroupClassName: "row",
                        fieldGroup: [
                            {
                                key: ["ClaimableEnvironmentType.Name"],
                                type: "input",
                                className: "col-4",
                                templateOptions: {
                                    label: "Claimable environment type name",
                                    placeholder: "Claimable environment type name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["ProjectTeam.Track.Name"],
                                type: "input",
                                className: "col-4",
                                templateOptions: {
                                    label: "Track name",
                                    placeholder: "Track name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["ProjectTeam.Name"],
                                type: "input",
                                className: "col-4",
                                templateOptions: {
                                    label: "Project team name",
                                    placeholder: "Project team name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["Number"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Number",
                                    placeholder: "Number",
                                    type: "number",
                                    required: false,
                                },
                            },

                            {
                                key: ["ForAdministratorOnly"],
                                type: "select",
                                className: "col-6",
                                templateOptions: {
                                    label: "For administrators only",
                                    required: false,
                                    options: [
                                        { value: null, label: "" },
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ],
                                    valueProp: "value",
                                    labelProp: "label",
                                },
                            },
                        ],
                    },
                ],

                whereConditionConfigurationList: [
                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "ClaimableEnvironmentType.Name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "Track.Name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "ProjectTeam.Name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "Number",
                            operator: "==",
                            value: null,
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "ForAdministratorOnly",
                            operator: "==",
                            value: null,
                        },
                    },
                ],
            },
        };
    }

    public getClaimableEnvironmentTypePaginatedFilterTableConfiguration(): IPaginatedFilterTableConfiguration<IExtendedClaimableEnvironmentType> {
        return {
            paginatedRequestHelper: new PaginatedRequestHelper(15, [
                { propertyName: "Name", ascending: false },
            ]),

            showNumberOfItemsFound: true,

            tableConfiguration: {
                tableColumnList: [
                    {
                        headerCellValue: "Identifier",
                        bodyCellValueSelector$: (item) => of(item.identifier),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "Identifier",
                    },

                    {
                        headerCellValue: "Label",
                        bodyCellValueSelector$: (item) => of(item.label),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "Label",
                    },

                    {
                        headerCellValue: "Name",
                        bodyCellValueSelector$: (item) => of(item.name),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "Name",
                    },

                    {
                        headerCellValue: "Jenkins job name",
                        bodyCellValueSelector$: (item) => of(item.jenkinsJobName),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "JenkinsJobName",
                    },

                    {
                        headerCellValue: "Jenkins job url",
                        bodyCellValueSelector$: (item) => of(item.jenkinsJobUrl),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "JenkinsJobUrl",
                    },

                    {
                        headerCellValue: "Require build version",
                        bodyCellValueSelector$: (item) => of(item.requireBuildVersion === true ? "Yes" : (item.requireBuildVersion === false ? "No" : "")),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "RequireBuildVersion",
                    },

                    {
                        headerCellValue: "Disable claiming",
                        bodyCellValueSelector$: (item) => of(item.disableClaiming === true ? "Yes" : (item.disableClaiming === false ? "No" : "")),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "DisableClaiming",
                    },

                    {
                        headerCellValue: "Disable claiming reason",
                        bodyCellValueSelector$: (item) => of(item.disableClaimingReason),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "DisableClaimingReason",
                    },
                ],
            },

            paginationConfiguration: {
                showIfOnlyOnePage: false,
                boundaryLinks: true,
                maxSize: 7,
            },

            filterConfiguration: {
                isCollapsed: true,
                model: {},
                formlyFormOptions: {},

                formlyFieldConfigList: [
                    {
                        fieldGroupClassName: "row",
                        fieldGroup: [
                            {
                                key: ["Label"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Label",
                                    placeholder: "Label",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["Name"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Name",
                                    placeholder: "Name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["RequireBuildVersion"],
                                type: "select",
                                className: "col-6",
                                templateOptions: {
                                    label: "Require build version",
                                    required: false,
                                    options: [
                                        { value: null, label: "" },
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ],
                                    valueProp: "value",
                                    labelProp: "label",
                                },
                            },

                            {
                                key: ["DisableClaiming"],
                                type: "select",
                                className: "col-6",
                                templateOptions: {
                                    label: "Disable claiming",
                                    required: false,
                                    options: [
                                        { value: null, label: "" },
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ],
                                    valueProp: "value",
                                    labelProp: "label",
                                },
                            },
                        ],
                    },
                ],

                whereConditionConfigurationList: [
                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "Label",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "Name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "RequireBuildVersion",
                            operator: "==",
                            value: null,
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "DisableClaiming",
                            operator: "==",
                            value: null,
                        },
                    },
                ],
            },
        };
    }

    public getCrossVistaTrackTemplatePaginatedFilterTableConfiguration(): IPaginatedFilterTableConfiguration<IExtendedCrossVistaTrackTemplate> {
        return {
            paginatedRequestHelper: new PaginatedRequestHelper(15, [
                { propertyName: "Name", ascending: false },
            ]),

            showNumberOfItemsFound: true,

            tableConfiguration: {
                tableColumnList: [
                    {
                        headerCellValue: "Name",
                        bodyCellValueSelector$: (item) => of(item.name),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "Name",
                    },

                    {
                        headerCellValue: "CtrEntityCode",
                        bodyCellValueSelector$: (item) => of(item.ctrEntityCode),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "CtrEntityCode",
                    },
                ],
            },

            paginationConfiguration: {
                showIfOnlyOnePage: false,
                boundaryLinks: true,
                maxSize: 7,
            },

            filterConfiguration: {
                isCollapsed: true,
                model: {},
                formlyFormOptions: {},

                formlyFieldConfigList: [
                    {
                        fieldGroupClassName: "row",
                        fieldGroup: [
                            {
                                key: ["Name"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Name",
                                    placeholder: "Name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["CtrEntityCode"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "CtrEntityCode",
                                    placeholder: "CtrEntityCode",
                                    type: "text",
                                    required: false,
                                },
                            },
                        ],
                    },
                ],

                whereConditionConfigurationList: [
                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "Name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "CtrEntityCode",
                            operator: "@=*",
                            value: "",
                        },
                    },
                ],
            },
        };
    }

    public getEnvironmentClaimPaginatedFilterTableConfiguration(): IPaginatedFilterTableConfiguration<IExtendedEnvironmentClaim> {
        return {
            paginatedRequestHelper: new PaginatedRequestHelper(15, [
                { propertyName: "ClaimDateStart", ascending: false },
            ]),

            showNumberOfItemsFound: true,

            tableConfiguration: {
                tableColumnList: [
                    {
                        headerCellValue: "Claimable environment type name",
                        bodyCellValueSelector$: (item) => of(item.extendedClaimableEnvironment.extendedClaimableEnvironmentType.name),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ClaimableEnvironment.ClaimableEnvironmentType.Name",
                    },

                    {
                        headerCellValue: "Claimable environment number",
                        bodyCellValueSelector$: (item) => of(item.extendedClaimableEnvironment.number.toString()),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ClaimableEnvironment.Number",
                    },

                    {
                        headerCellValue: "User email address",
                        bodyCellValueSelector$: (item) => of(item.extendedUser.emailAddress),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "User.EmailAddress",
                    },

                    {
                        headerCellValue: "Claim CrossVista track template",
                        bodyCellValueSelector$: (item) => of(item.claimCrossVistaTrackTemplate === true ? "Yes" : (item.claimCrossVistaTrackTemplate === false ? "No" : "")),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ClaimCrossVistaTrackTemplate",
                    },

                    {
                        headerCellValue: "Azure DevOps work item id",
                        bodyCellValueSelector$: (item) => of(item.azureDevOpsWorkItemId.toString()),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "AzureDevOpsWorkItemId",
                    },

                    {
                        headerCellValue: "Build version",
                        bodyCellValueSelector$: (item) => of(item.buildVersion),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "BuildVersion",
                    },

                    {
                        headerCellValue: "Claim date start",
                        bodyCellValueSelector$: (item) => of(item.claimDateStart),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ClaimDateStart",
                    },

                    {
                        headerCellValue: "Claim date end",
                        bodyCellValueSelector$: (item) => of(item.claimDateEnd),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ClaimDateEnd",
                    },

                    {
                        headerCellValue: "Claim build job number",
                        bodyCellValueSelector$: (item) => of(item.claimBuildJobNumber != null ? item.claimBuildJobNumber.toString() : ""),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ClaimBuildJobNumber",
                    },

                    {
                        headerCellValue: "Claim build job successful",
                        bodyCellValueSelector$: (item) => of(item.claimBuildJobSuccessful === true ? "Yes" : (item.claimBuildJobSuccessful === false ? "No" : "")),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ClaimBuildJobSuccessful",
                    },

                    {
                        headerCellValue: "Unclaim build job number",
                        bodyCellValueSelector$: (item) => of(item.unclaimBuildJobNumber != null ? item.unclaimBuildJobNumber.toString() : ""),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "UnclaimBuildJobNumber",
                    },

                    {
                        headerCellValue: "Unclaim build job successful",
                        bodyCellValueSelector$: (item) => of(item.unclaimBuildJobSuccessful === true ? "Yes" : (item.unclaimBuildJobSuccessful === false ? "No" : "")),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "UnclaimBuildJobSuccessful",
                    },

                    {
                        headerCellValue: "Last updated",
                        bodyCellValueSelector$: (item) => of(item.lastUpdated),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "LastUpdated",
                    },
                ],
            },

            paginationConfiguration: {
                showIfOnlyOnePage: false,
                boundaryLinks: true,
                maxSize: 7,
            },

            filterConfiguration: {
                isCollapsed: true,
                model: {},
                formlyFormOptions: {},

                formlyFieldConfigList: [
                    {
                        fieldGroupClassName: "row",
                        fieldGroup: [
                            {
                                key: ["ClaimableEnvironment.ClaimableEnvironmentType.Name"],
                                type: "input",
                                className: "col-4",
                                templateOptions: {
                                    label: "Claimable environment type name",
                                    placeholder: "Claimable environment type name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["User.EmailAddress"],
                                type: "input",
                                className: "col-4",
                                templateOptions: {
                                    label: "User email address",
                                    placeholder: "User email address",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["AzureDevOpsWorkItemId"],
                                type: "input",
                                className: "col-4",
                                templateOptions: {
                                    label: "Azure DevOps work item id",
                                    placeholder: "Azure DevOps work item id",
                                    type: "number",
                                    required: false,
                                },
                            },

                            {
                                key: ["ClaimableEnvironment.Number"],
                                type: "input",
                                className: "col-3",
                                templateOptions: {
                                    label: "Claimable environment number",
                                    placeholder: "Claimable environment number",
                                    type: "number",
                                    required: false,
                                },
                            },

                            {
                                key: ["ClaimCrossVistaTrackTemplate"],
                                type: "select",
                                className: "col-3",
                                templateOptions: {
                                    label: "Claim CrossVista track template",
                                    required: false,
                                    options: [
                                        { value: null, label: "" },
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ],
                                    valueProp: "value",
                                    labelProp: "label",
                                },
                            },

                            {
                                key: ["ClaimBuildJobSuccessful"],
                                type: "select",
                                className: "col-3",
                                templateOptions: {
                                    label: "Claim build job successful",
                                    required: false,
                                    options: [
                                        { value: null, label: "" },
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ],
                                    valueProp: "value",
                                    labelProp: "label",
                                },
                            },

                            {
                                key: ["UnclaimBuildJobSuccessful"],
                                type: "select",
                                className: "col-3",
                                templateOptions: {
                                    label: "Unclaim build job successful",
                                    required: false,
                                    options: [
                                        { value: null, label: "" },
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ],
                                    valueProp: "value",
                                    labelProp: "label",
                                },
                            },
                        ],
                    },
                ],

                whereConditionConfigurationList: [
                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "ClaimableEnvironment.ClaimableEnvironmentType.Name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "User.EmailAddress",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "AzureDevOpsWorkItemId",
                            operator: "==",
                            value: null,
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "ClaimableEnvironment.Number",
                            operator: "==",
                            value: null,
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "ClaimCrossVistaTrackTemplate",
                            operator: "==",
                            value: null,
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "ClaimBuildJobSuccessful",
                            operator: "==",
                            value: null,
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "UnclaimBuildJobSuccessful",
                            operator: "==",
                            value: null,
                        },
                    },
                ],
            },
        };
    }

    public getProjectTeamPaginatedFilterTableConfiguration(): IPaginatedFilterTableConfiguration<IExtendedProjectTeam> {
        return {
            paginatedRequestHelper: new PaginatedRequestHelper(15, [
                { propertyName: "Track.Name", ascending: false },
                { propertyName: "Name", ascending: false },
            ]),

            showNumberOfItemsFound: true,

            tableConfiguration: {
                tableColumnList: [
                    {
                        headerCellValue: "Track name",
                        bodyCellValueSelector$: (item) => of(item.extendedTrack.name),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "Track.Name",
                    },

                    {
                        headerCellValue: "Name",
                        bodyCellValueSelector$: (item) => of(item.name),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "Name",
                    },

                    {
                        headerCellValue: "Label",
                        bodyCellValueSelector$: (item) => of(item.label),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "Label",
                    },

                    {
                        headerCellValue: "Email address",
                        bodyCellValueSelector$: (item) => of(item.emailAddress),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "EmailAddress",
                    },
                ],
            },

            paginationConfiguration: {
                showIfOnlyOnePage: false,
                boundaryLinks: true,
                maxSize: 7,
            },

            filterConfiguration: {
                isCollapsed: true,
                model: {},
                formlyFormOptions: {},

                formlyFieldConfigList: [
                    {
                        fieldGroupClassName: "row",
                        fieldGroup: [
                            {
                                key: ["Track.Name"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Track name",
                                    placeholder: "Track name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["Name"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Name",
                                    placeholder: "Name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["Label"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Label",
                                    placeholder: "Label",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["EmailAddress"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Email address",
                                    placeholder: "Email address",
                                    type: "text",
                                    required: false,
                                },
                            },
                        ],
                    },
                ],

                whereConditionConfigurationList: [
                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "Track.Name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "Name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "Label",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "EmailAddress",
                            operator: "@=*",
                            value: "",
                        },
                    },
                ],
            },
        };
    }

    public getTrackPaginatedFilterTableConfiguration(): IPaginatedFilterTableConfiguration<IExtendedTrack> {
        return {
            paginatedRequestHelper: new PaginatedRequestHelper(15, [
                { propertyName: "Name", ascending: false },
            ]),

            showNumberOfItemsFound: true,

            tableConfiguration: {
                tableColumnList: [
                    {
                        headerCellValue: "Name",
                        bodyCellValueSelector$: (item) => of(item.name),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "Name",
                    },
                ],
            },

            paginationConfiguration: {
                showIfOnlyOnePage: false,
                boundaryLinks: true,
                maxSize: 7,
            },

            filterConfiguration: {
                isCollapsed: true,
                model: {},
                formlyFormOptions: {},

                formlyFieldConfigList: [
                    {
                        fieldGroupClassName: "row",
                        fieldGroup: [
                            {
                                key: ["Name"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Name",
                                    placeholder: "Name",
                                    type: "text",
                                    required: false,
                                },
                            },
                        ],
                    },
                ],

                whereConditionConfigurationList: [
                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "Name",
                            operator: "@=*",
                            value: "",
                        },
                    },
                ],
            },
        };
    }

    public getUserPaginatedFilterTableConfiguration(): IPaginatedFilterTableConfiguration<IExtendedUser> {
        return {
            paginatedRequestHelper: new PaginatedRequestHelper(15, [
                { propertyName: "EmailAddress", ascending: false },
            ]),

            showNumberOfItemsFound: true,

            tableConfiguration: {
                tableColumnList: [
                    {
                        headerCellValue: "Track name",
                        bodyCellValueSelector$: (item) => of(item.extendedProjectTeam.extendedTrack.name),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ProjectTeam.Track.Name",
                    },

                    {
                        headerCellValue: "Project team name",
                        bodyCellValueSelector$: (item) => of(item.extendedProjectTeam.name),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "ProjectTeam.Name",
                    },

                    {
                        headerCellValue: "Email address",
                        bodyCellValueSelector$: (item) => of(item.emailAddress),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "EmailAddress",
                    },

                    {
                        headerCellValue: "Is administrator",
                        bodyCellValueSelector$: (item) => of(item.isAdministrator === true ? "Yes" : (item.isAdministrator === false ? "No" : "")),
                        headerCellCssClassList: ["align-middle"],
                        sortPropertyName: "IsAdministrator",
                    },
                ],
            },

            paginationConfiguration: {
                showIfOnlyOnePage: false,
                boundaryLinks: true,
                maxSize: 7,
            },

            filterConfiguration: {
                isCollapsed: true,
                model: {},
                formlyFormOptions: {},

                formlyFieldConfigList: [
                    {
                        fieldGroupClassName: "row",
                        fieldGroup: [
                            {
                                key: ["ProjectTeam.Track.Name"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Track name",
                                    placeholder: "Track name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["ProjectTeam.Name"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Project team name",
                                    placeholder: "Project team name",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["EmailAddress"],
                                type: "input",
                                className: "col-6",
                                templateOptions: {
                                    label: "Email address",
                                    placeholder: "Email address",
                                    type: "text",
                                    required: false,
                                },
                            },

                            {
                                key: ["IsAdministrator"],
                                type: "select",
                                className: "col-6",
                                templateOptions: {
                                    label: "Is administrator",
                                    required: false,
                                    options: [
                                        { value: null, label: "" },
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ],
                                    valueProp: "value",
                                    labelProp: "label",
                                },
                            },
                        ],
                    },
                ],

                whereConditionConfigurationList: [
                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "ProjectTeam.Track.Name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "ProjectTeam.Name",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "EmailAddress",
                            operator: "@=*",
                            value: "",
                        },
                    },

                    {
                        ignoreIfEmpty: true,
                        whereCondition: {
                            propertyName: "IsAdministrator",
                            operator: "==",
                            value: null,
                        },
                    },
                ],
            },
        };
    }
}