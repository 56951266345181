import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginatedRequestParameters, IPaginatedResult } from "rabobank-utilities";
import { Observable } from "rxjs";
import { API_URL } from "../InjectionTokens";
import { ICrossVistaTrackTemplate } from "../models/ICrossVistaTrackTemplate";
import { BaseWebApi } from "./BaseWebApi";

@Injectable()
export class CrossVistaTrackTemplateWebApi extends BaseWebApi {
    public constructor(
        private httpClient: HttpClient,
        @Inject(API_URL) private apiUrl: string,
    ) { 
        super();
    }

    public getPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<ICrossVistaTrackTemplate>> {
        return this.httpClient.get<IPaginatedResult<ICrossVistaTrackTemplate>>(this.apiUrl + "/CrossVistaTrackTemplate?" + this.getSieveQueryString(paginatedRequestParameters), { withCredentials: true });
    }

    public create$(crossVistaTrackTemplate: ICrossVistaTrackTemplate): Observable<number> {
        return this.httpClient.post<number>(this.apiUrl + "/CrossVistaTrackTemplate", crossVistaTrackTemplate, { withCredentials: true });
    }

    public update$(id: number, crossVistaTrackTemplate: ICrossVistaTrackTemplate): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/CrossVistaTrackTemplate/" + id, crossVistaTrackTemplate, { withCredentials: true });
    }

    public delete$(id: number): Observable<void> {
        return this.httpClient.delete<void>(this.apiUrl + "/CrossVistaTrackTemplate/" + id, { withCredentials: true });
    }
}
