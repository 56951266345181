import { ChangeDetectorRef, Component } from "@angular/core";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { Store } from "@ngrx/store";
import { IMenuItem, IUserInformation } from "rabobank-layout";
import { ApiCallHelper, AppConfigService, BaseComponent, CommonModalService } from "rabobank-utilities";
import { ReplaySubject, combineLatest, of } from "rxjs";
import { filter, map, mergeMap, startWith, take, takeUntil } from "rxjs/operators";
import { IEnvironmentConfiguration } from "src/app/models/IEnvironmentConfiguration";
import { IExtendedClaimableEnvironmentType } from "src/app/models/IExtendedClaimableEnvironmentType";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceGrfd1Hub, IUser } from "src/app/services/GovernanceGrfd1Api";
import { IAppState, UserInformationActions } from "src/app/store";

@Component({
    selector: "governance-grfd1-app-root",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class AppRootComponent extends BaseComponent {
    public applicationName: string = "Governance GRFD1";
    public applicationNameSubtitle: string | null = null;
    public sidebarMenuItemList: IMenuItem[] = [];
    public navbarMenuItemList$: ReplaySubject<IMenuItem[]> = new ReplaySubject<IMenuItem[]>(1);
    public pageName$: ReplaySubject<string> = new ReplaySubject<string>(1);
    public userInformation$: ReplaySubject<IUserInformation> = new ReplaySubject<IUserInformation>(1);

    public constructor(
        public appConfigService: AppConfigService<IEnvironmentConfiguration>,
        private store: Store<IAppState>,
        private changeDetectorRef: ChangeDetectorRef,
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        private userInformationActions: UserInformationActions,
        private msalBroadcastService: MsalBroadcastService,
        private governanceGrfd1Hub: GovernanceGrfd1Hub
    ) {
        super();

        this.applicationNameSubtitle = appConfigService.environmentConfiguration.applicationNameSubtitle;

        this.initialize();
    }

    private initialize(): void {
        combineLatest([
            this.onInit$,
            this.msalBroadcastService.inProgress$.pipe(filter(status => status == InteractionStatus.None))
        ])
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.governanceGrfd1Hub.governanceGrfd1Data.connect();

                    this.msalBroadcastService.msalSubject$
                        .pipe(filter((eventMessage: EventMessage) => eventMessage.eventType === EventType.LOGIN_FAILURE))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe((eventMessage: EventMessage) => {
                            if (eventMessage.error?.name == "InteractionRequiredAuthError" && eventMessage.error.message.startsWith("interaction_required: AADSTS50105:")) {
                                this.commonModalService.showErrorModal("You have not been authorized to access this application. Please contact IT4IT if you need to access this application.", "Authorization error");
                            }
                            else {
                                this.commonModalService.showErrorModal("Something went wrong while logging in. Error message: " + eventMessage.error?.message);
                            }
                        });

                    this.store.select(x => x.navbar.title)
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (title) => {
                                this.pageName$.next(title);

                                //When the title is updated from a child component through the store, this line prevents a ExpressionChangedAfterItHasBeenCheckedError from happening
                                this.changeDetectorRef.detectChanges();
                            },
                        });

                    this.store.select(x => x.navbar.menuItemList)
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (menuItemList) => {
                                this.navbarMenuItemList$.next(menuItemList);

                                //When the navbar menu item list is updated from a child component through the store, this line prevents a ExpressionChangedAfterItHasBeenCheckedError from happening
                                this.changeDetectorRef.detectChanges();
                            },
                        });

                    //Whenever the user list changes, update the user in the store
                    this.extendedObjectApiCallHelper.extendedUserListUpdated$
                        .pipe(startWith([null]))
                        .pipe(mergeMap(() => {
                            return this.extendedObjectApiCallHelper.getCurrentUser$();
                        }))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (user) => {
                                this.userInformationActions.setUser(user);
                            },
                        });

                    //Whenever the stored user changes, update the local userInformation
                    this.store.select(x => x.userInformation.user)
                        .pipe(filter(x => x != null))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (user) => {
                                this.userInformation$.next({
                                    emailAddress: user!.emailAddress,
                                    givenName: "",
                                    middleName: "",
                                    name: "",
                                    surName: "",
                                });
                            },
                        });

                    //Whenever the user in the store or claimable environment type list change, reload the sidebar menu
                    combineLatest([
                        this.store.select(x => x.userInformation.user).pipe(filter(x => x != null)),
                        this.extendedObjectApiCallHelper.extendedClaimableEnvironmentTypeListUpdated$.pipe(startWith([null])),
                    ])
                        .pipe(mergeMap(([
                            user,
                        ]) => {
                            return combineLatest([
                                of(user),
                                this.extendedObjectApiCallHelper.getClaimableEnvironmentTypePaginatedResult$({
                                    page: 1,
                                    itemsPerPage: 1000,
                                    whereConditionList: [],
                                    sortConditionList: [{ propertyName: "Name", ascending: true }],
                                })
                                    .pipe(map((paginatedResult) => paginatedResult.itemList)),
                            ]);
                        }))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: ([
                                user,
                                claimableEnvironmentTypeList,
                            ]) => {
                                this.loadSidebarMenuItemList(user, claimableEnvironmentTypeList);
                            },
                        });
                },
            });
    }

    public loadSidebarMenuItemList(user: IUser, claimableEnvironmentTypeList: IExtendedClaimableEnvironmentType[]) {
        let menuItemList: IMenuItem[] = [];

        let environmentsMenuItem: IMenuItem = {
            label: "Environments",
            id: "environments",
            subMenuItemList: [],
        };

        menuItemList.push(environmentsMenuItem);

        for (let claimableEnvironmentType of claimableEnvironmentTypeList) {
            environmentsMenuItem.subMenuItemList.push({
                label: claimableEnvironmentType.name,
                id: "claim-overview-" + claimableEnvironmentType.identifier,
                routerLink: ["home", "claim-overview", claimableEnvironmentType.identifier],
            });
        }

        if (user.isAdministrator == true) {
            menuItemList.push({
                label: "Administration",
                id: "administration",
                subMenuItemList: [
                    {
                        label: "Claimable environments",
                        id: "claimable-environments",
                        routerLink: ["administration", "claimable-environment-manage"],
                    },

                    {
                        label: "Claimable environment types",
                        id: "claimable-environment-types",
                        routerLink: ["administration", "claimable-environment-type-manage"],
                    },

                    {
                        label: "CrossVista track template",
                        id: "crossvista-track-templates",
                        routerLink: ["administration", "cross-vista-track-template-manage"],
                    },

                    {
                        label: "Environment claims",
                        id: "environment-claims",
                        routerLink: ["administration", "environment-claim-manage"],
                    },

                    {
                        label: "Project teams",
                        id: "project-teams",
                        routerLink: ["administration", "project-team-manage"],
                    },

                    {
                        label: "Tracks",
                        id: "tracks",
                        routerLink: ["administration", "track-manage"],
                    },

                    {
                        label: "Users",
                        id: "users",
                        routerLink: ["administration", "user-manage"],
                    },
                ],
            });
        }

        this.sidebarMenuItemList = menuItemList;
    }
}
