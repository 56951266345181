<div class="modal-header">
    <h5 class="modal-title">Claim environment {{claimableEnvironment?.number}}</h5>
    <fa-icon class="btn close"
             [icon]="['fas', 'times']"
             size="lg"
             (click)="bsModalRef.hide()"></fa-icon>
</div>
<div class="modal-body">
    <governance-grfd1-start-environment-claim-form *ngIf="claimableEnvironment != null"
                                             [dataProvider]="dataProvider"
                                             [claimableEnvironmentId]="claimableEnvironment.id"
                                             [claimableEnvironmentTypeId]="claimableEnvironment.extendedClaimableEnvironmentType.id"
                                             [requireBuildVersion]="claimableEnvironment.extendedClaimableEnvironmentType.requireBuildVersion"
                                             (formSubmit)="onFormSubmit($event)"></governance-grfd1-start-environment-claim-form>
</div>