import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { BaseComponent, IPaginatedFilterTableModalFieldTypeConfiguration } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { IExtendedClaimableEnvironment } from "src/app/models/IExtendedClaimableEnvironment";
import { IExtendedCrossVistaTrackTemplate } from "src/app/models/IExtendedCrossVistaTrackTemplate";
import { IExtendedUser } from "src/app/models/IExtendedUser";
import { IEnvironmentClaim } from "src/app/services/GovernanceGrfd1Api";
import { PaginatedFilterTableConfigurationService } from "src/app/services/PaginatedFilterTableConfigurationService";
import { buildVersionValidator } from "src/app/services/Validators";
import { IDataProvider } from "./IDataProvider";

@Component({
    selector: "governance-grfd1-environment-claim-form",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class EnvironmentClaimFormComponent extends BaseComponent {
    @Input() dataProvider: IDataProvider;

    @Output("formSubmit") public formSubmitEventEmitter: EventEmitter<IEnvironmentClaim> = new EventEmitter<IEnvironmentClaim>();

    public formGroup: FormGroup = new FormGroup({});
    public model: IEnvironmentClaim = {
        claimCrossVistaTrackTemplate: true,
        environmentClaimJenkinsUpdateList: [],
    } as IEnvironmentClaim;
    public formlyFormOptions: FormlyFormOptions = {};
    public formlyFieldConfigList: FormlyFieldConfig[];

    public constructor(
        private paginatedFilterTableConfigurationService: PaginatedFilterTableConfigurationService,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.formlyFieldConfigList = [
                        {
                            key: ["id"],
                            type: "input",
                            templateOptions: {
                                label: "Id",
                                placeholder: "Id",
                                type: "number",
                                required: false,
                            },
                            hide: true,
                        },

                        {
                            key: ["claimableEnvironmentId"],
                            type: "paginated-filter-table-modal",
                            templateOptions: {
                                label: "Claimable environment",
                                type: "number",
                                required: true,
                                paginatedFilterTableModalFieldTypeConfiguration: {
                                    modalTitle: "Select a claimable environment",

                                    modalConfig: {
                                        class: "col-12 col-md-8",
                                        ignoreBackdropClick: true,
                                    },

                                    paginatedFilterTableConfiguration: this.paginatedFilterTableConfigurationService.getClaimableEnvironmentPaginatedFilterTableConfiguration(),

                                    dataProvider: {
                                        getPaginatedResult$: (paginatedRequestParameters) => this.dataProvider.getClaimableEnvironmentPaginatedResult$(paginatedRequestParameters),
                                        listUpdated$: this.dataProvider.claimableEnvironmentListUpdated$,
                                    },

                                    getItemFromValue$: (id) => this.dataProvider.getClaimableEnvironmentFromValue$(id),

                                    itemValueSelector: (item) => item?.id,

                                    itemLabelSelector: (item) => item != null ? (item.extendedClaimableEnvironmentType.name + " - " + item.number) : "No claimable environment selected",
                                } as IPaginatedFilterTableModalFieldTypeConfiguration<IExtendedClaimableEnvironment, number>,
                            },
                        },

                        {
                            key: ["crossVistaTrackTemplateId"],
                            type: "paginated-filter-table-modal",
                            templateOptions: {
                                label: "CrossVista track template",
                                type: "number",
                                required: false,
                                paginatedFilterTableModalFieldTypeConfiguration: {
                                    modalTitle: "Select a CrossVista track template",

                                    modalConfig: {
                                        class: "col-12 col-md-8",
                                        ignoreBackdropClick: true,
                                    },

                                    paginatedFilterTableConfiguration: this.paginatedFilterTableConfigurationService.getCrossVistaTrackTemplatePaginatedFilterTableConfiguration(),

                                    dataProvider: {
                                        getPaginatedResult$: (paginatedRequestParameters) => this.dataProvider.getCrossVistaTrackTemplatePaginatedResult$(paginatedRequestParameters),
                                        listUpdated$: this.dataProvider.crossVistaTrackTemplateListUpdated$,
                                    },

                                    getItemFromValue$: (id) => this.dataProvider.getCrossVistaTrackTemplateFromValue$(id),

                                    itemValueSelector: (item) => item?.id,

                                    itemLabelSelector: (item) => item != null ? (item.name) : "No CrossVista track template selected",
                                } as IPaginatedFilterTableModalFieldTypeConfiguration<IExtendedCrossVistaTrackTemplate, number>,
                            },
                        },

                        {
                            key: ["userId"],
                            type: "paginated-filter-table-modal",
                            templateOptions: {
                                label: "User",
                                type: "number",
                                required: true,
                                paginatedFilterTableModalFieldTypeConfiguration: {
                                    modalTitle: "Select a user",

                                    modalConfig: {
                                        class: "col-12 col-md-8",
                                        ignoreBackdropClick: true,
                                    },

                                    paginatedFilterTableConfiguration: this.paginatedFilterTableConfigurationService.getUserPaginatedFilterTableConfiguration(),

                                    dataProvider: {
                                        getPaginatedResult$: (paginatedRequestParameters) => this.dataProvider.getUserPaginatedResult$(paginatedRequestParameters),
                                        listUpdated$: this.dataProvider.userListUpdated$,
                                    },

                                    getItemFromValue$: (id) => this.dataProvider.getUserFromValue$(id),

                                    itemValueSelector: (item) => item?.id,

                                    itemLabelSelector: (item) => item != null ? item.emailAddress : "No claimable environment selected",
                                } as IPaginatedFilterTableModalFieldTypeConfiguration<IExtendedUser, number>,
                            },
                        },

                        {
                            key: ["claimCrossVistaTrackTemplate"],
                            type: "checkbox",
                            templateOptions: {
                                label: "Claim CrossVista track template",
                                required: true,
                            },
                        },

                        {
                            key: ["azureDevOpsWorkItemId"],
                            type: "input",
                            templateOptions: {
                                label: "Azure DevOps work item id",
                                placeholder: "Azure DevOps work item id",
                                type: "number",
                                min: 0,
                                required: true,
                            },
                        },

                        {
                            key: ["buildVersion"],
                            type: "input",
                            templateOptions: {
                                label: "Build version",
                                placeholder: "Build version",
                                type: "text",
                                maxLength: 255,
                                required: true,
                            },
                            validators: {
                                buildVersion: buildVersionValidator,
                            },
                        },

                        {
                            key: ["claimDateStart"],
                            type: "input",
                            templateOptions: {
                                label: "Claim date start",
                                placeholder: "Claim date start",
                                type: "datetime-local",
                                required: true,
                            },
                        },

                        {
                            key: ["claimDateEnd"],
                            type: "input",
                            templateOptions: {
                                label: "Claim date end",
                                placeholder: "Claim date end",
                                type: "datetime-local",
                                required: false,
                            },
                        },

                        {
                            key: ["claimBuildJobNumber"],
                            type: "input",
                            templateOptions: {
                                label: "Jenkins claim build job number",
                                placeholder: "Jenkins claim build job number",
                                type: "number",
                                min: 0,
                                required: false,
                            },
                        },

                        {
                            key: ["claimBuildJobSuccessful"],
                            type: "select",
                            templateOptions: {
                                label: "Jenkins claim build job successful",
                                required: false,
                                options: [
                                    { value: null, label: "" },
                                    { value: true, label: "Yes" },
                                    { value: false, label: "No" },
                                ],
                                valueProp: (item: { value: boolean | null, label: string }) => item.value,
                                labelProp: (item: { value: boolean | null, label: string }) => item.label,
                            },
                        },

                        {
                            key: ["unclaimBuildJobNumber"],
                            type: "input",
                            templateOptions: {
                                label: "Jenkins unclaim build job number",
                                placeholder: "Jenkins unclaim build job number",
                                type: "number",
                                min: 0,
                                required: false,
                            },
                        },

                        {
                            key: ["unclaimBuildJobSuccessful"],
                            type: "select",
                            templateOptions: {
                                label: "Jenkins unclaim build job successful",
                                required: false,
                                options: [
                                    { value: null, label: "" },
                                    { value: true, label: "Yes" },
                                    { value: false, label: "No" },
                                ],
                                valueProp: (item: { value: boolean | null, label: string }) => item.value,
                                labelProp: (item: { value: boolean | null, label: string }) => item.label,
                            },
                        },

                        {
                            key: ["environmentClaimJenkinsUpdateList"],
                            type: "repeat-section",
                            templateOptions: {
                                headerText: "Jenkins updates",
                                addText: "Add another item",
                            },
                            fieldArray: {
                                fieldGroup: [
                                    {
                                        key: ["id"],
                                        type: "input",
                                        templateOptions: {
                                            label: "Id",
                                            placeholder: "Id",
                                            type: "number",
                                            required: false,
                                        },
                                        hide: true,
                                    },

                                    {
                                        key: ["environmentClaimId"],
                                        type: "input",
                                        templateOptions: {
                                            label: "Environment claim id",
                                            placeholder: "Environment claim id",
                                            type: "number",
                                            required: false,
                                        },
                                        hide: true,
                                    },

                                    {
                                        key: ["successMessage"],
                                        type: "checkbox",
                                        templateOptions: {
                                            label: "Success message",
                                            required: true,
                                        },
                                    },

                                    {
                                        key: ["message"],
                                        type: "input",
                                        templateOptions: {
                                            label: "Message",
                                            placeholder: "Message",
                                            type: "text",
                                            maxLength: 255,
                                            required: true,
                                        },
                                    },

                                    {
                                        key: ["date"],
                                        type: "input",
                                        templateOptions: {
                                            label: "Date",
                                            placeholder: "Date",
                                            type: "datetime-local",
                                            required: true,
                                        },
                                    },
                                ],
                            },
                        },

                        {
                            key: ["environmentClaimResyncList"],
                            type: "repeat-section",
                            templateOptions: {
                                headerText: "Resyncs",
                                addText: "Add another item",
                            },
                            fieldArray: {
                                fieldGroup: [
                                    {
                                        key: ["id"],
                                        type: "input",
                                        templateOptions: {
                                            label: "Id",
                                            placeholder: "Id",
                                            type: "number",
                                            required: false,
                                        },
                                        hide: true,
                                    },

                                    {
                                        key: ["environmentClaimId"],
                                        type: "input",
                                        templateOptions: {
                                            label: "Environment claim id",
                                            placeholder: "Environment claim id",
                                            type: "number",
                                            required: false,
                                        },
                                        hide: true,
                                    },

                                    {
                                        key: ["resyncDate"],
                                        type: "input",
                                        templateOptions: {
                                            label: "Date",
                                            placeholder: "Date",
                                            type: "datetime-local",
                                            required: true,
                                        },
                                    },

                                    {
                                        key: ["resyncBuildJobNumber"],
                                        type: "input",
                                        templateOptions: {
                                            label: "Jenkins resync build job number",
                                            placeholder: "Jenkins resync build job number",
                                            type: "number",
                                            min: 0,
                                            required: false,
                                        },
                                    },
            
                                    {
                                        key: ["resyncBuildJobSuccessful"],
                                        type: "select",
                                        templateOptions: {
                                            label: "Jenkins resync build job successful",
                                            required: false,
                                            options: [
                                                { value: null, label: "" },
                                                { value: true, label: "Yes" },
                                                { value: false, label: "No" },
                                            ],
                                            valueProp: (item: { value: boolean | null, label: string }) => item.value,
                                            labelProp: (item: { value: boolean | null, label: string }) => item.label,
                                        },
                                    },
                                ],
                            },
                        },
                    ];
                },
            });
    }

    public loadRecord(id: number): void {
        this.dataProvider.getEnvironmentClaim$(id)
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (environmentClaim) => {
                    this.model = environmentClaim;

                    this.formlyFormOptions.updateInitialValue();
                },
            });
    }

    public onFormSubmit(): void {
        if (this.formGroup.valid) {
            this.formSubmitEventEmitter.emit(this.model);
        }
    }
}