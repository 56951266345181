import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginatedRequestParameters, IPaginatedResult } from "rabobank-utilities";
import { Observable } from "rxjs";
import { API_URL } from "../InjectionTokens";
import { IClaimableEnvironmentType } from "../models/IClaimableEnvironmentType";
import { ISetDisableClaimingForAllClaimableEnvironmentTypesParameters } from "../models/parameters/ISetDisableClaimingForAllClaimableEnvironmentTypesParameters";
import { BaseWebApi } from "./BaseWebApi";

@Injectable()
export class ClaimableEnvironmentTypeWebApi extends BaseWebApi {
    public constructor(
        private httpClient: HttpClient,
        @Inject(API_URL) private apiUrl: string,
    ) { 
        super();
    }

    public getPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IClaimableEnvironmentType>> {
        return this.httpClient.get<IPaginatedResult<IClaimableEnvironmentType>>(this.apiUrl + "/ClaimableEnvironmentType?" + this.getSieveQueryString(paginatedRequestParameters), { withCredentials: true });
    }

    public create$(claimableEnvironmentType: IClaimableEnvironmentType): Observable<number> {
        return this.httpClient.post<number>(this.apiUrl + "/ClaimableEnvironmentType", claimableEnvironmentType, { withCredentials: true });
    }

    public update$(id: number, claimableEnvironmentType: IClaimableEnvironmentType): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/ClaimableEnvironmentType/" + id, claimableEnvironmentType, { withCredentials: true });
    }

    public delete$(id: number): Observable<void> {
        return this.httpClient.delete<void>(this.apiUrl + "/ClaimableEnvironmentType/" + id, { withCredentials: true });
    }

    public setDisableClaimingForAllClaimableEnvironmentTypes$(setDisableClaimingForAllClaimableEnvironmentTypesParameters: ISetDisableClaimingForAllClaimableEnvironmentTypesParameters): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/ClaimableEnvironmentType/SetDisableClaimingForAllClaimableEnvironmentTypes", setDisableClaimingForAllClaimableEnvironmentTypesParameters, { withCredentials: true });
    }
}
