<form [formGroup]="formGroup"
      (ngSubmit)="onFormSubmit()">
    <formly-form [model]="model"
                 [options]="formlyFormOptions"
                 [fields]="formlyFieldConfigList"
                 [form]="formGroup"></formly-form>

    <button type="submit"
            class="btn btn-primary submit-button"
            [disabled]="formGroup.invalid">Submit</button>

    <button type="button"
            class="btn btn-default"
            (click)="formlyFormOptions.resetModel!()">Reset</button>
</form>