import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginatedRequestParameters, IPaginatedResult } from "rabobank-utilities";
import { Observable } from "rxjs";
import { API_URL } from "../InjectionTokens";
import { IProjectTeam } from "../models/IProjectTeam";
import { BaseWebApi } from "./BaseWebApi";

@Injectable()
export class ProjectTeamWebApi extends BaseWebApi {
    public constructor(
        private httpClient: HttpClient,
        @Inject(API_URL) private apiUrl: string,
    ) { 
        super();
    }

    public getPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IProjectTeam>> {
        return this.httpClient.get<IPaginatedResult<IProjectTeam>>(this.apiUrl + "/ProjectTeam?" + this.getSieveQueryString(paginatedRequestParameters), { withCredentials: true });
    }

    public create$(projectTeam: IProjectTeam): Observable<number> {
        return this.httpClient.post<number>(this.apiUrl + "/ProjectTeam", projectTeam, { withCredentials: true });
    }

    public update$(id: number, projectTeam: IProjectTeam): Observable<void> {
        return this.httpClient.put<void>(this.apiUrl + "/ProjectTeam/" + id, projectTeam, { withCredentials: true });
    }

    public delete$(id: number): Observable<void> {
        return this.httpClient.delete<void>(this.apiUrl + "/ProjectTeam/" + id, { withCredentials: true });
    }
}
