<div class="row"
     *ngIf="(currentEnvironmentList$ | async) != null && (currentEnvironmentList$ | async)[0]?.extendedClaimableEnvironment?.extendedClaimableEnvironmentType?.disableClaiming == true">
    <div class="col-12">
        <div class="alert alert-danger">
            Claiming disabled: {{(currentEnvironmentList$ | async)[0].extendedClaimableEnvironment.extendedClaimableEnvironmentType.disableClaimingReason}}
        </div>
    </div>
</div>

<rabobank-utilities-paginated-filter-table [configuration]="paginatedFilterTableConfiguration"
                                           [paginatedResult$]="paginatedResult$"></rabobank-utilities-paginated-filter-table>

<ng-template #tableBodyCellClaimUnclaimButton
             let-item>
    <button *ngIf="(isClaimButtonVisible$(item) | async) == true"
            class="btn btn-primary btn-sm"
            (click)="onClaimEnvironment(item)">Claim</button>

    <button *ngIf="(isUnclaimButtonVisible$(item) | async) == true"
            class="btn btn-primary btn-sm"
            (click)="onUnclaimEnvironment(item)">Unclaim</button>
</ng-template>

<ng-template #tableBodyCellResyncButton
             let-item>
             <button *ngIf="(isResyncButtonVisible$(item) | async) == true"
             class="btn btn-primary btn-sm"
             (click)="onResyncEnvironment(item)">Resync</button>
</ng-template>

<ng-template #tableBodyCellJenkinsInformationButton
             let-item>
    <button *ngIf="item.extendedEnvironmentClaim != null"
            class="btn btn-secondary btn-sm"
            (click)="onShowJenkinsInformation(item)">Jenkins info</button>
</ng-template>

<ng-template #tableBodyCellAzureDevOpsWorkItemInformationButton
             let-item>
    <button *ngIf="item.extendedEnvironmentClaim != null"
            class="btn btn-secondary btn-sm"
            (click)="onShowAzureDevOpsWorkItemInformation(item)">Work item info</button>
</ng-template>

<ng-template #tableBodyCellClaimableEnvironmentNumber
             let-item>
    <span>{{item.extendedClaimableEnvironment.number}}</span>

    <button *ngIf="item.extendedClaimableEnvironment.forAdministratorOnly == true"
            class="btn btn-sm btn-link"
            [tooltip]="'For administrators only'">
        <fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
    </button>
</ng-template>

<ng-template #tableBodyCellBuildVersion
             let-item>
    <ng-container *ngIf="item.extendedEnvironmentClaim != null">
        <p class="mb-1">{{item.extendedEnvironmentClaim.buildVersion}}</p>

        <button *ngIf="isUpdateBuildVersionButtonVisible$(item)"
                class="btn btn-primary btn-sm"
                (click)="onUpdateBuildVersion(item)">Update</button>
    </ng-container>
</ng-template>

<ng-template #tableBodyCellAzureDevOpsWorkItemId
             let-item>
    <ng-container *ngIf="item.extendedEnvironmentClaim != null">
        <a [href]="item.extendedEnvironmentClaim.azureDevOpsWorkItem.url"
           target="_blank">{{item.extendedEnvironmentClaim.azureDevOpsWorkItem.id}}</a>

        <button class="btn btn-sm btn-link pt-0"
                [tooltip]="item.extendedEnvironmentClaim.azureDevOpsWorkItem.title">
            <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
        </button>
    </ng-container>
</ng-template>